import styled from "styled-components";
import media from "styled-media-query";

export const SafeView = styled.div`
  display: flex;
  margin: 30px;
  padding-bottom: 50px;
  background-color: #fff;
`;

export const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
`;

export const TopSpace = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  gap: 30px;

  ${media.greaterThan("medium")`
  width: 100%;
  height: 35%;
  `}
`;

export const Title = styled.p`
  font-size: 20px;
  font-weight: 400;
  text-align: center;
`;

export const Subtitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 20px;
`;

export const ContainerCardTemplate = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  margin-top: 100px;
`;

export const Card = styled.div`
  height: 300px;
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;

  img {
    width: 400px;
    height: 258px;
    border: 1px solid #d3d3d3;  
    border-radius: 8px;
  }
`;

export const Footer = styled.div`
  margin-top: 50px;
  bottom: 0;
  width: 100%;

  @media (max-width: 712px) {
  padding: 20px;
  text-align: center;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  }
`;