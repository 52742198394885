/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, {
  useEffect,
  useState,
  useMemo,
  useContext,
  useCallback,
} from "react";
import Layout from "components/OldLayout";
import Loading from "components/Loading";
import ContainerFlex from "components/ContainerFlex";
import Button from "components/Button";
import OneButtonPopup from "components/modals/OneButtonPopup";
import TwoButtonPopup from "components/modals/TwoButtonPopup";
import PasswordPopup from "components/modals/PasswordPopup";
import PictureModal from "components/PictureCard";
import InputRadio from "components/InputRadio";
import Card from "components/Card/cardFatura";
import ListBenefit from "components/ListBenefits";
import Dependent from "components/CardDependent";
import Select from "components/InputSelect/inputSelect.js";
import { moneyMask } from "utils/mask";
import moment from "moment";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import IconTrash from "assets/icons/trash.svg";

import { useHistory, useLocation } from "react-router-dom";
import { cpfMask, phoneMask, creditCardMask } from "utils/mask";
import assets from "assets/index";
import * as S from "./styles";
import Grid from "components/Grid";
import Dialog from "components/dialog";
import { AttendanceContext } from "context/AttendanceContext";
import useApi from "hooks/useApi";
import constants from "utils/constants";
import Repository from "repository";
import RadioButton from "components/radioButton";
import Spinner from "components/Spinner";
import colors from "styles/color";

export default function attendanceDetail() {
  const [data, setData] = useState(null);
  const [dataDocument, setDataDocument] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [msgDialog, setMsgDialog] = useState("");
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [faturaFeed, setFaturaFeed] = useState(false);
  const [userPicture, setUserPicture] = useState();
  const [justification, setJustification] = useState("");
  const [card, setCard] = useState("red");
  const [cardDesign, setCardDesign] = useState();
  const [fatura, setFatura] = useState(null);
  const [cardLayout, setCardLayout] = useState({
    red: true,
    blue: false,
    rose: false,
    ludico: false,
  });
  const [cardTarifa, setCardTarifa] = useState(true);
  const [passwordPopupVisibility, setPasswordPopupVisibility] = useState(false);
  const [isOpenSales, setIsOpenSales] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [invoiceBase64, setInvoiceBase64] = useState("");
  const [isCancel, setIsCancel] = useState(false);
  const [benefitSelected, setBenefitSelected] = useState(null);
  const [availableBenefits, setAvailableBenefits] = useState([]);
  const [contractedBenefits, setContractedBenefits] = useState();
  const [dialogJustificationIsOpen, setDialogJustificationIsOpen] =
    useState(false);
  const [dialogIsOpenDependent, setDialogIsOpenDependent] = useState(false);
  const [dialogIsOpenDetails, setDialogIsOpenDetails] = useState(false);
  const [benefitDetails, setBenefitDetails] = useState();
  const [totalValue, setTotalValue] = useState();
  const [dialogIsOpenDeleteBenfit, setDialogIsOpenDeleteBenefit] =
    useState(false);
  const [deleteDependentMessage, setDeleteDependentMessage] = useState("");
  const [dialogDeleteDependent, setDialogDeleteDependent] = useState(false);
  const [deleteBenefitMessage, setDeleteBenefitMessage] = useState("");
  const [dialogDeleteBenefit, setDialogDeleteBenefit] = useState(false);
  const [dialogIsOpenCancel, setDialogIsOpenCancel] = useState(false);
  const [idUsuario, setIdUsuario] = useState("");
  const [onCpf, setOnCpf] = useState(false);
  const [dialogIsOpenCancelCuida, setDialogIsOpenCancelCuida] = useState(false);
  const matchesQuery = useMediaQuery("(max-width: 768px)");
  const [cancelReason, setCancelReason] = useState("" | {});
  const [benefitDetailsDialogOpen, setBenefitDetailsDialogOpen] =
    useState(false);
  const [benefitDetailsDialog, setBenefitDetailsDialog] = useState({
    title: null,
    description: null,
    link: null,
  });
  const [cardsCategory, setCardsCategory] = useState([]);
  const [proposalNotSign, setProposalNotSign] = useState(false);

  const { state } = useLocation();
  const user = JSON.parse(localStorage.getItem("@NovaEra:user"));
  const history = useHistory();
  const {
    setCpf,
    setIdProposta,
    clientDetail,
    clientDocuments,
    message,
    error,
    getAvaliableBenefits,
    avaliableBenefits,
    loading,
    getContractedBenefits,
    contractedBenefit,
    getBenefitDependents,
    benefitDependents,
    deleteBenefit,
    postSecondWayCard,
    postTokenCard,
    postValidatePassword,
    cardToken,
    setCardNumber,
    getFatura,
    setSelectedInvoice,
    selectedInvoice,
    postSendEmail,
    deleteDependent,
    getFaturaPdf,
    postBenefit,
    cancelBenefit,
    cancelBenefitCuida,
    getClientDetail,
    setClientDetail,
  } = useContext(AttendanceContext);

  const isUserMoreThan65YearsOld =
    new Date().getFullYear() -
      new Date(clientDetail?.dataNascimento).getFullYear() >
    65;

  useEffect(() => {
    return () => {
      setClientDetail(null);
      setProposalNotSign(false);
    };
  }, []);

  useEffect(() => {
    if (data) {
      if (data?.proposta?.situacao !== "ASSINADA") {
        setProposalNotSign(true);
      }
    }
  }, [data]);

  useEffect(() => {
    setCpf(state?.cpf);
    setIdProposta(state?.id);
    setIdUsuario(state?.idUsuario);
    setOnCpf(true);
  }, [state]);

  useEffect(() => {
    if (onCpf) {
      const fetchData = async () => {
        if (state?.cpf) {
          await getClientDetail();
        }
      };
      fetchData();
    }
  }, [state, onCpf]);

  useEffect(() => {
    if (error) {
      setMsgDialog(message);
      setDialogIsOpen(true);
    }
  }, [error, message]);
  useEffect(() => {
    if (data?.cartaoAtual?.numeroCartao) {
      setCardNumber(data.cartaoAtual.numeroCartao);
    }
    if (clientDetail) {
      setData(clientDetail);
      setIsCancel(clientDetail.cartaoAtual.status !== "CANCELADO");
    }
    if (benefitDependents) {
      setBenefitDetails(benefitDependents?.detalhes);
      setTotalValue(benefitDependents?.valorParcelTotal);
    }
    if (avaliableBenefits) {
      setAvailableBenefits(avaliableBenefits);
    }
    if (contractedBenefit) {
      setContractedBenefits(contractedBenefit);
    }
    if (clientDocuments) {
      setDataDocument(clientDocuments);
      setUserPicture(clientDocuments.fotoCertiface);
    }
  }, [
    clientDetail,
    clientDocuments,
    error,
    avaliableBenefits,
    contractedBenefit,
    cardToken,
    benefitDependents,
    data,
  ]);

  useEffect(() => {
    if (isOpenSales) {
      populaBenefit();
    }
  }, [isOpenSales]);

  const populaBenefit = async () => {
    await getAvaliableBenefits();
    await getContractedBenefits();
    await getBenefitDependents();
  };

  const DeleteBenefit = useApi(
    constants.endpoints.deleteBenefit
      .replace("PARAM_ID_PROPOSAL", state?.id)
      .replace("PARAM_ID_BENEFIT", benefitSelected),
    "DELETE"
  );

  const GetCardsListDesign = useApi(
    constants.endpoints.getClientCategoryCard.replace("PARAM_CPF", state?.cpf),
    "GET"
  );

  const getCardsDesign = async () => {
    try {
      const response = await GetCardsListDesign.callApi();
      if (response.status === 200 && response.status <= 300) {
        setCardsCategory(response.data.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleDeleteBenefit = async () => {
    // await deleteBenefit();
    const response = await DeleteBenefit.callApi();
    if (response.status >= 200 && response.status <= 300) {
      setDialogIsOpenDeleteBenefit(false);
      await populaBenefit();
    } else {
      setDeleteBenefitMessage(response.mensagem);
      setDialogDeleteBenefit(true);
    }
  };

  const handleFatura = async () => {
    setIsOpenSales(false);
    setPasswordPopupVisibility(true);
  };

  const handleSegundaCartao = () => {
    setIsOpenSales(false);
    getCardsDesign();
    setOpenDialog(true);
    data.categoriaCartao === "PLATINUM"
      ? setCardDesign(data?.categoriaCartao)
      : setCardDesign(undefined);
  };

  const handlePOSTSegundaCartao = async () => {
    const payload = {
      numeroCartao: data.cartaoAtual.numeroCartao,
      taxa: cardTarifa,
      temaDoCartao: cardDesign,
    };
    const { status, mensagem } = await postSecondWayCard(payload);

    if (status >= 200 && status <= 300) {
      setOpenDialog(false);
      setDialogIsOpen(true);
      setMsgDialog("Segunda via do cartão solicitada com sucesso!");
      await getClientDetail();
    } else {
      setOpenDialog(false);
      setDialogIsOpen(true);
      setMsgDialog(mensagem ?? "Falha ao solicitar segunda via do cartão!");
    }
  };

  const handleCard = (e) => {
    setCardLayout({
      red: false,
      blue: false,
      rose: false,
      ludico: false,
    });

    setCardLayout((prev) => ({ ...prev, [e]: true }));
    setCard(e);
  };

  const validatePassword = useCallback(
    async (senha) => {
      setPasswordError(null);
      setPasswordPopupVisibility(false);

      const response = await postTokenCard({
        numeroCartao: data.cartaoAtual.numeroCartao,
      });

      if (response.isErrorToken) return response.mensagem;

      const { isError, status, mensagem } = await postValidatePassword({
        senha: senha,
        tokenCartao: response.tokenCartao && response.tokenCartao,
      });

      if (isError) {
        setPasswordPopupVisibility(true);
        setPasswordError(status === 403 ? "Senha inválida" : mensagem);
        return isError;
      }
      return isError;
    },
    [cardToken, data]
  );
  const handlePasswordPopup = async (password) => {
    const isError = await validatePassword(password);

    if (isError) return;
    await searchFatura();
  };
  const searchFatura = async () => {
    try {
      const { status, data, mensagem } = await getFatura();

      switch (status) {
        case 200:
          if (data.listaFaturas) {
            setFaturaFeed(false);
            setFatura(data.listaFaturas);
          } else {
            setFaturaFeed(true);
            setMsgDialog("Nenhuma fatura encontrada!");
            setDialogIsOpen(true);
          }
          return;
        case 204:
          return { status: 204 };
        default:
          setDialogIsOpen(true);
          throw setMsgDialog(mensagem || status);
      }
    } catch (err) {
      setFaturaFeed(true);
      setMsgDialog("Nenhuma fatura encontrada! ", message);
      setDialogIsOpen(true);
    }
  };

  const activeCardStatus = data?.cartaoAtual?.status;

  const isActiveCardNotAllowed = () => {
    if (!(activeCardStatus === "ATIVO" || activeCardStatus === "INATIVO")) {
      return true;
    }
    return false;
  };

  const sendEmail = async () => {
    const { status } = await postSendEmail();

    if (status === 200 || status === 204) {
      setMsgDialog("Fatura enviada com sucesso para o e-mail!");
    } else {
      setMsgDialog("Fatura não encontrada");
    }
  };
  const downloadPdf = async () => {
    const response = await getFaturaPdf();
    switch (response.status) {
      case 200:
        setInvoiceBase64(response.data.documentFatura);
        return printInvoice(response.data.documentFatura);
      default:
        setDialogIsOpen(true);
        return setMsgDialog(
          `Não foi possível enviar a fatura por e-mail! Status do erro: ${response.status}`
        );
    }
  };
  useEffect(() => {
    if (selectedInvoice?.type === "email") {
      sendEmail();
    }
    if (selectedInvoice?.type === "pdf") {
      downloadPdf();
    }
  }, [selectedInvoice]);

  const handleSendEmail = (faturaSelect) => {
    setSelectedInvoice({ type: "email", invoice: faturaSelect });
  };
  const handlePrint = async (faturaSelect) => {
    setSelectedInvoice({ type: "pdf", invoice: faturaSelect });
  };
  const handleDeleteDependent = async (dependent) => {
    try {
      const response = await deleteDependent({
        numeroDocumento: dependent.numeroDocumento || "",
      });

      if (response.status === 200 || response.status === 204) {
        setDeleteDependentMessage("Dependente excluído com sucesso!");
        setDialogDeleteDependent(true);
      } else {
        setDeleteDependentMessage(
          response.mensagem ||
            "Não é possível excluir pois a carteirinha está em processamento. Por favor aguarde 5 minutos."
        );
        setDialogDeleteDependent(true);
      }
    } catch {
      setDeleteDependentMessage(
        "Não é possível excluir pois a carteirinha está em processamento. Por favor aguarde 5 minutos."
      );
      setDialogDeleteDependent(true);
    }
  };

  const printInvoice = (invoice) => {
    const binary = atob(invoice.replace(/\s/g, ""));
    const buffer = new ArrayBuffer(binary.length);
    const view = new Uint8Array(buffer);

    for (let i = 0; i < binary.length; i += 1) {
      view[i] = binary.charCodeAt(i);
    }

    const blob = new Blob([view], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(blob);
    return window.open(fileURL, "_blank");
  };

  const handleCardRender = (faturaSelect) => {
    return (
      <Card
        onClickSendEmail={handleSendEmail}
        onClickPrint={handlePrint}
        key={faturaSelect.id}
        data={faturaSelect}
      />
    );
  };

  const handleCardListRender = useMemo(
    () => fatura?.map(handleCardRender),
    [fatura, handleCardRender]
  );

  //contratar beneficio (emoji rosa)
  const confirmMembership = async () => {
    if (
      isUserMoreThan65YearsOld &&
      ![1, 5].includes(benefitSelected?.idBeneficioAutorizador)
    ) {
      setMsgDialog(
        "Ops.... Adesão negada! Não é permitido para maiores de 65 anos."
      );
      setDialogJustificationIsOpen(false);
      setDialogIsOpen(true);
      return;
    }

    const payload = [
      {
        idProposta: state?.id,
        idOperador: user?.id,
        cpf: state?.cpf,
        beneficios: [
          {
            idBeneficio: benefitSelected.id,
            statusBeneficio: "ATIVO",
            assinada: false,
          },
        ],
      },
    ];

    try {
      const response = await postBenefit(payload);
      if (response.status === 200 || response.status === 204) {
        populaBenefit();
        if (benefitSelected.idBeneficioAutorizador === 1) {
          setDialogIsOpenDependent(true);
          return;
        }

        history.push("/assinatura-beneficio", {
          idProposal: state?.id,
          benefit: [benefitSelected],
          email: data.email,
        });
      }
    } catch (err) {
      setMsgDialog("Falha ao aderir ao benefício!");
      setDialogIsOpen(true);
    } finally {
      setDialogJustificationIsOpen(false);
    }
  };
  const handleCheckBenefitLink = (link) => {
    const a = document.createElement("a");
    a.href = link;
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    a.parentNode.removeChild(a);
  };

  function hasThreeDaysPassed(providedDate) {
    const now = moment();
    const date = moment(providedDate, 'YYYY-MM-DD');
    const diffInDays = now.diff(date, 'days');

    return diffInDays >= 3
  }

  const handleCheckReprintCard = () => {
    if (hasThreeDaysPassed(data?.cartaoAtual?.dataEmissao)) {
      setDialogIsOpen(true);
      setMsgDialog("O cartão foi emitido há mais de tês dias. Necessário emitir uma segunda via.")
      return
    }

      history.push("/livenessReprintCard", {
        data: {
          ...state,
          nome: data?.nome,
          email: data?.email,
          dataNascimento: data?.dataNascimento,
          temaDoCartao: data?.cartaoAtual?.temaDoCartao,
        },
      })
   
  }

  const handleAssinar = async (benefit) => {
    if (benefit.idBeneficioAutorizador === 1) {
      const valorParcelTotal = benefitDependents?.valorParcelTotal;
      if (valorParcelTotal) benefit.valorMensalidade = valorParcelTotal;
    }
    history.push("/assinatura-beneficio", {
      idProposal: state?.id,
      email: data.email,
      benefit: [benefit],
    });
  };

  const handleCancelBenefit = async (payload) => {
    const { status, data, mensagem } = await cancelBenefit(payload);
    if (status >= 200 && status <= 300) {
      setMsgDialog("Cancelamento realizado com sucesso!");
      setDialogIsOpen(true);
      setDialogIsOpenCancel(false);
      setCancelReason("");
      await populaBenefit();
    } else {
      setMsgDialog(mensagem);
      setDialogIsOpen(true);
      setCancelReason("");
      setDialogIsOpenCancel(false);
    }
  };

  const handleCancelBenefitCuida = async (payload) => {
    const { status, data, mensagem } = await cancelBenefitCuida(payload);
    if (status >= 200 && status <= 300) {
      setMsgDialog("Cancelamento realizado com sucesso!");
      setDialogIsOpen(true);
      setDialogIsOpenCancelCuida(false);
      setCancelReason("");
      await populaBenefit();
    } else {
      setMsgDialog(mensagem);
      setDialogIsOpen(true);
      setCancelReason("");
      setDialogIsOpenCancelCuida(false);
    }
  };
  const objectReasons = {
    DIFICULADADE_FINANCEIRA: "Dificuldade financeira",
    DESEJA_CANCELAR_CARTAO: "Deseja cancelar o cartão",
    CONTRATACAO_SEM_AUTORIZACAO: "Contratação sem autorização",
    ERRO_LOJA_CONTRATACAO_INDEVIDA: "Erro loja - Contratação indevida",
    OUTROS_MOTIVOS: "Outros motivos",
  };

  return (
    <S.Container>
      <Loading status={loading || DeleteBenefit.loading} />
      <Dialog
        icon={assets.png.tick}
        widthInPercent={matchesQuery ? 90 : 40}
        open={proposalNotSign}
        positiveLabel="Entendi"
        title={"Ops..."}
        positiveAction={() => {
          history.push("/attendance", undefined);
        }}
        message={
          "Identificamos que esta proposta ainda não foi assinada. Para prosseguir realize a assinatura!"
        }
      />
      <OneButtonPopup
        defaultButton
        text={msgDialog}
        buttonText="Entendi"
        visibility={dialogIsOpen}
        setVisibility={() => setDialogIsOpen(false)}
      />
      <Dialog
        widthInPercent={30}
        icon={assets.png.exclamation}
        open={benefitDetailsDialogOpen}
        negativeLabel="Voltar"
        negativeAction={() => setBenefitDetailsDialogOpen(false)}
        positiveLabel="Mais Detalhes"
        positiveAction={() => handleCheckBenefitLink(benefitDetailsDialog.link)}
        title={
          <span style={{ color: "red" }}>{benefitDetailsDialog.title}</span>
        }
      >
        <span
          style={{ fontSize: "1.3em", fontWeight: 600, textAlign: "center" }}
        >
          {benefitDetailsDialog.description}
        </span>
      </Dialog>
      <TwoButtonPopup
        text={
          <span
            style={{ fontSize: "1em", fontWeight: 600, textAlign: "center" }}
          >
            Deseja solicitar a adesão?
          </span>
        }
        buttonCancelText="Voltar"
        buttonText="Solicitar"
        visibility={dialogJustificationIsOpen}
        setVisibility={(val) => setDialogJustificationIsOpen(val)}
        onClick={confirmMembership}
      />
      <TwoButtonPopup
        text={
          <span
            style={{ fontSize: "1em", fontWeight: 600, textAlign: "center" }}
          >
            Deseja excluir benefício?
          </span>
        }
        buttonCancelText="Cancelar"
        buttonText="Excluir"
        visibility={dialogIsOpenDeleteBenfit}
        setVisibility={(val) => setDialogIsOpenDeleteBenefit(val)}
        onClick={handleDeleteBenefit}
      />
      <OneButtonPopup
        defaultButton
        text={deleteBenefitMessage}
        buttonText="Entendi"
        visibility={dialogDeleteBenefit}
        setVisibility={() => setDialogDeleteBenefit(false)}
      />
      <TwoButtonPopup
        text="Solicitação de 2ª via de Cartão"
        visibility={openDialog}
        buttonText="Solicitar"
        onClick={handlePOSTSegundaCartao}
        buttonDisabledPositive={cardDesign === undefined}
        buttonCancelText="Voltar"
        width={70}
        setVisibility={(val) => {
          setOpenDialog(val);
          setCardDesign("");
        }}
      >
        <S.divClient>
          <S.Text name={"text1"}>Escolha o design do cartão</S.Text>

          <S.divRadioBtn>
            {GetCardsListDesign.loading ? (
              <Spinner color={colors.orange.n2} />
            ) : (
              cardsCategory?.map((el, index) => (
                <S.ContainerCards key={index}>
                  <S.IconCardDesign src={el.urlImagem} />
                  <RadioButton
                    id={el.idExterno}
                    name={el.modeloTema}
                    checked={cardDesign === el.modeloTema}
                    onClick={() => setCardDesign(el.modeloTema)}
                  />
                </S.ContainerCards>
              ))
            )}
          </S.divRadioBtn>
          <S.divRadioBtn>
            <S.Text name={"text1"}>Cobrar Tarifa ?</S.Text>

            <S.RadioGroup
              name="tarifaCard"
              id="tarifaCard"
              value={cardTarifa}
              onChange={(e) => handleCard(e.target.value)}
              style={{ margin: 0 }}
            >
              <InputRadio
                name="cardTarifaSim"
                id="cardTarifaSim"
                type="radio"
                label="Sim"
                value={true}
                onChange={() => setCardTarifa(true)}
                checked={cardTarifa === true}
              />
            </S.RadioGroup>
          </S.divRadioBtn>
        </S.divClient>
      </TwoButtonPopup>
      {dialogIsOpenDependent && (
        <Dependent
          isOpenDependent={dialogIsOpenDependent}
          stateValue={{
            idBenefit: benefitSelected,
            idProposta: state?.id,
            idOperador: user?.id,
            email: data.email,
            cpf: state?.cpf,
          }}
          contractedBenefits={benefitSelected}
          setLoading={(bool) => setLoading(bool)}
          setDialogIsOpen={(bool) => setDialogIsOpen(bool)}
          setMsgDialog={(str) => setMsgDialog(str)}
          setDialogIsOpenDialog={(bool) => setDialogIsOpenDependent(bool)}
        />
      )}
      {dialogIsOpenCancelCuida && (
        <TwoButtonPopup
          visibility={dialogIsOpenCancelCuida}
          setVisibility={(val) => {
            setCancelReason("");
            setDialogIsOpenCancelCuida(val);
          }}
          onClick={() => {
            if (benefitSelected?.idBeneficioAutorizador === 5) {
              const payload = {
                idProposta: state?.id,
                idBeneficio: benefitSelected.id,
                motivoCancelamento: cancelReason?.value,
                idUsuario: idUsuario,
              };
              handleCancelBenefitCuida(payload);
            }
          }}
          text="Cancelar benefício"
          width={matchesQuery ? 90 : 50}
          buttonText="Continuar"
          buttonCancelText="Voltar"
          buttonDisabledPositive={!cancelReason?.value}
          icon={assets.png.cancel}
        >
          <div style={{ marginTop: "20px" }}>
            <Select
              label="Escolha um motivo de cancelamento"
              id="motivo"
              name="motivo"
              onChange={(e) =>
                setCancelReason({ value: e.target.value, label: e.target.name })
              }
              values={[
                {
                  value: "",
                  label: "",
                  selected: true,
                  disabled: true,
                  hidden: true,
                },
                {
                  value: "DIFICULADADE_FINANCEIRA",
                  label: "Dificuldade financeira",
                },
                {
                  value: "DESEJA_CANCELAR_CARTAO",
                  label: "Deseja cancelar o cartão",
                },
                {
                  value: "CONTRATACAO_SEM_AUTORIZACAO",
                  label: "Contratação sem autorização",
                },
                {
                  value: "ERRO_LOJA_CONTRATACAO_INDEVIDA",
                  label: "Erro loja - Contratação indevida",
                },
                { value: "OUTROS_MOTIVOS", label: "Outros motivos" },
              ]}
            />
            {cancelReason?.value && (
              <span style={{ fontSize: "1.5rem" }}>
                Deseja cancelar o seguro <b>{benefitSelected?.nome}</b> pelo
                motivo <b>{objectReasons[cancelReason?.value]}</b>?
              </span>
            )}
          </div>
        </TwoButtonPopup>
      )}
      {dialogIsOpenCancel && (
        <TwoButtonPopup
          visibility={dialogIsOpenCancel}
          setVisibility={(val) => {
            setCancelReason("");
            setDialogIsOpenCancel(val);
          }}
          onClick={() => {
            benefitSelected?.idBeneficioAutorizador === 1
              ? handleCancelBenefit({
                  idProposta: state?.id,
                  idBeneficio: benefitSelected.id,
                  motivoCancelamento: cancelReason?.value,
                  idUsuario: idUsuario,
                })
              : history.push("/assinatura-termo-cancelamento", {
                  idProposta: state?.id,
                  beneficio: [benefitSelected],
                  motivoCancelamento: cancelReason?.value,
                  idUsuario: idUsuario,
                });
          }}
          text="Cancelar benefício"
          width={matchesQuery ? 90 : 50}
          buttonText="Continuar"
          buttonCancelText="Voltar"
          buttonDisabledPositive={!cancelReason?.value}
          icon={assets.png.cancel}
        >
          <div style={{ marginTop: "20px" }}>
            <Select
              label="Escolha um motivo de cancelamento"
              id="motivo"
              name="motivo"
              onChange={(e) =>
                setCancelReason({ value: e.target.value, label: e.target.name })
              }
              values={[
                {
                  value: "",
                  label: "",
                  selected: true,
                  disabled: true,
                  hidden: true,
                },
                {
                  value: "DIFICULADADE_FINANCEIRA",
                  label: "Dificuldade financeira",
                },
                {
                  value: "DESEJA_CANCELAR_CARTAO",
                  label: "Deseja cancelar o cartão",
                },
                {
                  value: "CONTRATACAO_SEM_AUTORIZACAO",
                  label: "Contratação sem autorização",
                },
                {
                  value: "ERRO_LOJA_CONTRATACAO_INDEVIDA",
                  label: "Erro loja - Contratação indevida",
                },
                { value: "OUTROS_MOTIVOS", label: "Outros motivos" },
              ]}
            />
            {cancelReason?.value && (
              <span style={{ fontSize: "1.5rem" }}>
                Deseja cancelar o seguro <b>{benefitSelected?.nome}</b> pelo
                motivo <b>{objectReasons[cancelReason?.value]}</b>?
              </span>
            )}
          </div>
        </TwoButtonPopup>
      )}
      {dialogIsOpenDetails && (
        <Dialog
          open={dialogIsOpenDetails}
          widthInPercent={matchesQuery ? 90 : 50}
          title={
            <span style={{ color: "red", fontSize: "2rem" }}>
              Detalhes do benefício
            </span>
          }
          positiveLabel="Voltar"
          positiveAction={() => setDialogIsOpenDetails(false)}
          icon={assets.png.tick}
        >
          <S.TableHistory>
            <tr>
              <th>Adesão</th>
              <th>Nome</th>
              <th>Parcela (R$)</th>
              <th>Data de adesão</th>
              <th>Assinado</th>
              <th>Status</th>
              <th style={{ borderRight: "1px solid grey" }}>Excluir</th>
            </tr>
            {benefitDetails?.map((benefit) => (
              <tr>
                <td>{benefit.adesao}</td>
                <td>{benefit.nome}</td>
                <td>{moneyMask(benefit.parcela)}</td>

                <td>{moment(benefit.dataAdesao).format("DD/MM/YYYY")}</td>
                <td>{benefit.assinado}</td>
                <td>{benefit.status}</td>
                <td style={{ borderRight: "1px solid grey" }}>
                  {benefit.adesao !== "Titular" && (
                    <img
                      style={{ cursor: "pointer" }}
                      width="18"
                      src={IconTrash}
                      alt="trash"
                      onClick={() => handleDeleteDependent(benefit)}
                    />
                  )}
                </td>
              </tr>
            ))}
          </S.TableHistory>
          <Dialog
            icon={assets.png.tick}
            widthInPercent={matchesQuery ? 90 : 40}
            open={dialogDeleteDependent}
            positiveLabel="Entendi"
            positiveAction={() => {
              populaBenefit();
              setDialogDeleteDependent(false);
            }}
            message={deleteDependentMessage}
          />
        </Dialog>
        /* No statusBeneficio foi feito dessa forma pois atualmente só existe um beneficio */
      )}
      <PasswordPopup
        onClick={handlePasswordPopup}
        text="Continuar"
        visibility={passwordPopupVisibility}
        setVisibility={() => {
          setPasswordError(null);
          setPasswordPopupVisibility(false);
        }}
        errorMessage={passwordError}
      />

      <Layout routeBack="/attendance" title="Informações do cliente">
        <ContainerFlex
          style={{
            width: "100%",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <S.Picture>
            <PictureModal
              id="picture-modal"
              source={
                userPicture
                  ? `data:image/png;base64, ${userPicture}`
                  : assets.png.userAvatar
              }
              width="160"
              height="160"
              expand
            />
          </S.Picture>
          <S.divClient>
            <S.ClientContent>
              <S.labelTitle>CPF:</S.labelTitle>
              <S.labelValue>{data && cpfMask(data.cpf).masked}</S.labelValue>
            </S.ClientContent>
            <S.ClientContent>
              <S.labelTitle>Nome:</S.labelTitle>
              <S.labelValue>{data && data.nome}</S.labelValue>
            </S.ClientContent>
            <S.ClientContent>
              <S.labelTitle>Telefone:</S.labelTitle>
              <S.labelValue>
                {data && phoneMask(data.celular).masked}
              </S.labelValue>
            </S.ClientContent>
            <S.ClientContent>
              <S.labelTitle>E-mail:</S.labelTitle>
              <S.labelValue>{data && data.email}</S.labelValue>
            </S.ClientContent>
            <S.ClientContent>
              <S.labelTitle>Número do Cartão:</S.labelTitle>
              <S.labelValue>
                {data && creditCardMask(data.cartaoAtual.numeroCartao).masked}
              </S.labelValue>
            </S.ClientContent>
            <S.ClientContent>
              <S.labelTitle>Status do Cartão:</S.labelTitle>
              <S.labelValue>{data && data.cartaoAtual.status}</S.labelValue>
            </S.ClientContent>
            <S.ClientContent>
              <S.labelTitle>Categoria do Cartão:</S.labelTitle>
              <S.labelValue>{data && data.categoriaCartao}</S.labelValue>
            </S.ClientContent>
            <S.ClientContent>
              <S.labelTitle>Modelo do Cartão:</S.labelTitle>
              <S.labelValue>
                {data && data.cartaoAtual.temaDoCartao}
              </S.labelValue>
            </S.ClientContent>
          </S.divClient>
        </ContainerFlex>
        <Grid
          xs={1}
          md={2}
          lg={2}
          style={{
            width: "100%",
            gap: "10%",
            padding: 60,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            buttonType="primary"
            text={"Fatura"}
            disabled={isLoading || data?.proposta?.situacao !== "ASSINADA"}
            onClick={handleFatura}
          />
          <Button
            buttonType="primary"
            text={"2ª via de cartão"}
            disabled={
              data?.cartaoAtual.status !== "CANCELADO" ||
              data?.proposta?.situacao !== "ASSINADA"
            }
            onClick={handleSegundaCartao}
          />
          <Button
            buttonType="primary"
            text="Venda"
            disabled={
              !(
                data?.cartaoAtual.status === "ATIVO" ||
                data?.cartaoAtual.status === "INATIVO"
              ) || data?.proposta?.situacao !== "ASSINADA"
            }
            onClick={() => setIsOpenSales(true)}
          />
          {data?.categoriaCartao === "PADRÃO" && (
            <Button
              buttonType="primary"
              text={"Upgrade de cartão"}
              onClick={() =>
                history.push("/change-category", {
                  data: { ...state, nome: data?.nome, email: data?.email },
                })
              }
              disabled={
                !(data?.cartaoAtual.status === "ATIVO") ||
                data?.proposta?.situacao !== "ASSINADA"
              }
            />
          )}
          <Button
            buttonType="primary"
            text={"Reimprimir cartão"}
            onClick={handleCheckReprintCard}
            disabled={!(data?.cartaoAtual.status === "INATIVO")}
          />
        </Grid>
      </Layout>
      {isOpenSales && (
        <ContainerFlex center flex align="center">
          <S.ClientContent>
            {contractedBenefits?.beneficios?.map((benefit, id, arr) => {
              const prevIsSameStatus = arr[id - 1];
              const prevIsSameStatus2 = arr[id + arr.length - 1];

              if (
                prevIsSameStatus?.statusBeneficio === benefit.statusBeneficio ||
                prevIsSameStatus2?.statusBeneficio === benefit.statusBeneficio
              ) {
                return null;
              }
              return (
                <>
                  {benefit.assinada && benefit.statusBeneficio === "ATIVO" ? (
                    <ContainerFlex row align="center">
                      <img
                        width="35"
                        src={assets.icons.emojiAtivo}
                        alt="emojiGreen"
                        style={{ marginRight: "20px", marginLeft: "10px" }}
                      />
                      <S.labelValue>Benefício Ativo</S.labelValue>
                    </ContainerFlex>
                  ) : benefit.statusBeneficio === "SUSPENSO" ? (
                    <ContainerFlex row align="center">
                      <img
                        width="35"
                        src={assets.icons.emojiSuspenso}
                        alt="checkOrange"
                        style={{ marginRight: "20px", marginLeft: "10px" }}
                      />
                      <S.labelValue>Benefício Suspenso</S.labelValue>
                    </ContainerFlex>
                  ) : benefit.statusBeneficio === "PENDENTE" ? (
                    <ContainerFlex row align="center">
                      <img
                        width="35"
                        src={assets.icons.emojiPendente}
                        alt="checkYellow"
                        style={{ marginRight: "20px", marginLeft: "10px" }}
                      />
                      <S.labelValue>Benefício Pendente</S.labelValue>
                    </ContainerFlex>
                  ) : benefit.statusBeneficio === "CANCELADO" ? (
                    <ContainerFlex row align="center">
                      <img
                        width="35"
                        src={assets.icons.emojiCancelado}
                        alt="checkBlue"
                        style={{ marginRight: "20px", marginLeft: "10px" }}
                      />
                      <S.labelValue>Benefício Cancelado</S.labelValue>
                    </ContainerFlex>
                  ) : (
                    <ContainerFlex row align="center">
                      <img
                        width="35"
                        src={assets.icons.emojiSemAdesao}
                        alt="checkBlue"
                        style={{ marginRight: "20px", marginLeft: "10px" }}
                      />
                      <S.labelValue>
                        Não permitido adicionar benefício
                      </S.labelValue>
                    </ContainerFlex>
                  )}
                </>
              );
            })}
          </S.ClientContent>
        </ContainerFlex>
      )}
      <S.WrapperList>{!error && handleCardListRender}</S.WrapperList>

      {isOpenSales && (
        <ListBenefit
          benefitDetailsDialog={benefitDetailsDialog}
          benefitDetailsDialogOpen={benefitDetailsDialogOpen}
          setBenefitDetailsDialogOpen={setBenefitDetailsDialogOpen}
          setBenefitDetailsDialog={setBenefitDetailsDialog}
          totalValue={totalValue}
          handleAssinar={(benefit) => handleAssinar(benefit)}
          benefitSelected={benefitSelected}
          availableBenefitsNotContracteds={availableBenefits}
          contractedBenefits={contractedBenefits}
          setBenefitSelected={(id) => setBenefitSelected(id)}
          setDialogIsOpenDeleteBenefit={(bool) =>
            setDialogIsOpenDeleteBenefit(bool)
          }
          setDialogIsOpenDependent={(bool) => setDialogIsOpenDependent(bool)}
          setDialogIsOpenCancel={(bool) => setDialogIsOpenCancel(bool)}
          setDialogIsOpenCancelBenefitCuida={(bool) =>
            setDialogIsOpenCancelCuida(bool)
          }
          setDialogJustificationIsOpen={(bool) =>
            setDialogJustificationIsOpen(bool)
          }
          setDialogIsOpenDetails={(bool) => setDialogIsOpenDetails(bool)}
          stateValue={{
            idBenefit: benefitSelected,
            idProposta: state?.id,
            idOperador: user?.id,
            cpf: state?.cpf,
          }}
        />
      )}

      {faturaFeed && (
        <ContainerFlex row horizontal center>
          <S.NotFound>Nenhuma fatura encontrada.</S.NotFound>
        </ContainerFlex>
      )}
    </S.Container>
  );
}
