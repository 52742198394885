/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Layout from "components/OldLayout";
import Input from "components/Input";
import * as St from "./styles";
import ContainerFlex from "components/ContainerFlex";
import Button from "components/Button";
import PictureModal from "components/PictureCard";
import useReactRouter from "use-react-router";
import assets from "assets/index";
import useApi from "hooks/useApi";
import constants from "utils/constants";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Loading from "components/Loading";

const inputStyle = {
  border: "1px solid #6c6c6c",
  padding: "1rem",
  borderRadius: ".5rem",
  width: "100%",
  height: "40%",
};

function ConsultProposalBeforeSignature() {
  const [contractInfo, setContractInfo] = useState();
  const [proposalInfo, setProposalInfo] = useState();
  const [isLoading, setLoading] = useState(false);

  const { history } = useReactRouter();

  const { state } = useLocation();

  const GetProposalClientModal = useApi(
    constants.endpoints.proposalClientModal.replace(
      "PARAM_ID_PROPOSAL",
      state?.propostaId
    ),
    "GET"
  );

  const GetClientDocuments = useApi(
    constants.endpoints.getClientDocument.replace(
      "PARAM_QUERY_STRING",
      state?.propostaId
    ),
    "GET"
  );

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { data } = await GetClientDocuments.callApi();
        const responseData = await GetProposalClientModal.callApi();

        const proposalInfo = {
          email: responseData?.data?.email,
          nome: responseData?.data?.nomeCompleto,
          diaVencimento: responseData?.data?.diaVencimento,
          limiteTotal:
            responseData?.data?.limiteCredito +
            responseData?.data?.limiteSaqueConcedido,
          limiteCompras: responseData?.data?.limiteCredito,
          limiteSaque: responseData?.data?.limiteSaqueConcedido,
          fotoCertiface: data?.fotoCertiface,
          statusCartao: responseData?.data?.statusCartao,
        };
        setContractInfo(responseData.data);
        setProposalInfo(proposalInfo);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const handleAccept = () => {
    const benefitNotContracted = state?.benefits?.notContracted;
    const benefitContracted = state?.benefits?.contracted;

      if (benefitNotContracted.length > 0) {
        history.push("/beneficios", {
          propostaId: state?.propostaId,
          cpf: state?.cpf,
          isUserMoreThan65YearsOld: state?.isUserMoreThan65YearsOld,
          benefits: {
            contracted: benefitContracted,
            notContracted: benefitNotContracted,
          },
        });
    }
  };

  return (
    <St.SafeView>
      <Loading status={isLoading} />
      <Layout
        routeBack="/consulta-status-proposta"
        title="Confirme tua proposta"
      >
        <St.Container>
          <St.TopSpace>
            <St.Title>
              Para assinar, confirme se os dados da proposta estão corretos
            </St.Title>

            <St.Inputs>
              <div
                style={{
                  width: "100%",
                  marginBottom: "15px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <St.Picture>
                  <PictureModal
                    id="picture-modal"
                    source={
                      proposalInfo?.fotoCertiface
                        ? `data:imagem/png;base64, ${proposalInfo.fotoCertiface}`
                        : assets.png.userAvatar
                    }
                    width="190"
                    height="190"
                    expand
                  />
                </St.Picture>
              </div>

              <Input
                boldLabel
                style={inputStyle}
                label="Status"
                name="status"
                disabled={true}
                value={"BLOQUEADO"}
              />
              <Input
                boldLabel
                style={inputStyle}
                label="Nome do cliente"
                name="nome"
                disabled={true}
                value={proposalInfo?.nome}
              />
              <Input
                boldLabel
                style={inputStyle}
                label="E-mail"
                name="email"
                disabled={true}
                value={proposalInfo?.email}
              />
              <Input
                boldLabel
                style={inputStyle}
                label="Dia do vencimento da fatura"
                name="vencimento"
                disabled={true}
                value={proposalInfo?.diaVencimento}
              />
              <Input
                boldLabel
                style={inputStyle}
                label="Limite de crédito total"
                name="limite"
                disabled={true}
                value={`R$ ${proposalInfo?.limiteTotal}`}
              />
              <Input
                boldLabel
                style={inputStyle}
                label="Limite de crédito para compras"
                name="compras"
                disabled={true}
                value={`R$ ${proposalInfo?.limiteCompras}`}
              />
              <Input
                boldLabel
                style={inputStyle}
                label="Limite de crédito para saque"
                name="saque"
                disabled={true}
                value={`R$ ${proposalInfo?.limiteSaque}`}
              />
            </St.Inputs>
            <ContainerFlex row space marginTop={20} style={{ gap: "30px" }}>
              <Button
                text="Recusar"
                buttonType="secondary"
                onClick={() => history.push("/opcoes-acesso")}
              />
              <Button text="Aceitar" buttonType="primary" onClick={handleAccept}/>
            </ContainerFlex>
          </St.TopSpace>
        </St.Container>
      </Layout>
    </St.SafeView>
  );
}
export default ConsultProposalBeforeSignature;
