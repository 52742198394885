/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import * as moment from "moment";
import ContainerFlex from "components/ContainerFlex";
import Input from "components/Input";
import Dialog from "components/dialog";
import Button from "components/buttonNew";
import useReactRouter from "use-react-router";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import constants from "utils/constants";

import { dateValidator, nameValidator } from "utils/validators";

import * as St from "./styles";
import assets from "assets";
import { cpfMask, dateMask, cpfUnmask, phoneMask } from "utils/mask";
import useApi from "hooks/useApi";
import Loading from "components/Loading";
import { isDateValid } from "../../utils/validators";

export default function dependent({
  isOpenDependent,
  setDialogIsOpenDialog,
  stateValue,
  setMsgDialog,
  setDialogIsOpen,
  setLoading,
  contractedBenefits,
}) {
  const matchesQuery = useMediaQuery("(max-width: 768px)");
  const [form, setForm] = useState({
    cpf: "",
    nome: "",
    dataNascimento: "",
    telefone: "",
  });
  const [listDepentent, setListDepentent] = useState([]);
  const dialogIsOpenDependent = useState(isOpenDependent);
  const [errorValidate, setError] = useState({
    nome: "",
    dataNascimento: "",
  });
  const [dialogIsOpenComf, setDialogIsOpenComf] = useState(false);

  const { history } = useReactRouter();

  const PostDependent = useApi(
    constants.endpoints.postDependent.replace(
      "PARAM_ID_PROPOSAL",
      stateValue.idProposta
    ),
    "POST"
  );
  const GetBenefitDependents = useApi(
    constants.endpoints.getBenefitDependents.replace(
      "PARAM_ID_PROPOSAL",
      stateValue.idProposta
    ),
    "GET"
  );

  async function handleClick() {
    let error = {
      nome: nameValidator(form.nome),
      dataNascimento: isDateValid(form.dataNascimento),
    };
    setError(error);
    if (error.nome.error) return;
    if (error.dataNascimento.error) return;

    const payload = {
      id: listDepentent.length + 1,
      cpf: form.cpf,
      nome: form.nome,
      dataNascimento: form.dataNascimento,
      telefone: form.telefone,
    };

    setListDepentent([...listDepentent, payload]);
    setForm({
      cpf: "",
      nome: "",
      dataNascimento: "",
      telefone: "",
    });
  }

  const teuCardOdonto = { ...contractedBenefits };

  const handleAssinar = async () => {
    const { data } = await GetBenefitDependents.callApi();

    history.push("/assinatura-beneficio", {
      idProposal: stateValue.idProposta,
      email: stateValue.email,
      benefit: [
        {
          idBeneficioAutorizador: teuCardOdonto.idBeneficioAutorizador,
          nome: teuCardOdonto.nome,
          descricao: teuCardOdonto.descricao,
          valorMensalidade:
            data?.valorParcelTotal ?? teuCardOdonto.valorMensalidade,
          link: teuCardOdonto.link,
          id: teuCardOdonto.id,
          linkContrato: teuCardOdonto.linkContrato,
        },
      ],
    });
  };

  const handleRemove = (data) => {
    const ind = listDepentent.indexOf(data);
    if (ind > -1) {
      const a = listDepentent.filter((e) => {
        return e !== data;
      });
      setListDepentent(a);
    }
    return listDepentent;
  };

  async function addDependent(listDependents) {
    setDialogIsOpenDialog(false);
    setLoading(true);
    let payload = [];
    listDependents.map((dependents) => {
      payload.push({
        nome: dependents.nome,
        dataNascimento: moment(dependents.dataNascimento, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        ),
        cpf: cpfUnmask(dependents.cpf),
        telefone: listDependents.telefone,
        idOperador: stateValue.idOperador,
      });
    });

    try {
      const response = await PostDependent.callApi(payload);

      if (response.status >= 200 && response.status < 300) {
        getDependent();
      }
    } catch (err) {
      setMsgDialog("Não foi possível adicionar dependente(s)!");
      setDialogIsOpen(true);
    } finally {
      setLoading(false);
    }
  }

  // Chama o serviço para pegar o valor atualizado do beneficio. /v1/ficha-proposta/obter-beneficio-dependentes
  // Depois chama segue o fluxo para assinar o beneficio.
  const getDependent = async () => {
    const { data, status } = await GetBenefitDependents.callApi();

    if (status >= 200 && status < 300) {
      handleAssinar(data.valorParcelTotal);
    }
  };

  return (
    <>
      <Loading status={GetBenefitDependents.loading || PostDependent.loading} />
      <Dialog
        title={<span style={{ color: "red" }}>Solicitação de dependente</span>}
        icon={assets.png.tick}
        open={dialogIsOpenDependent}
        widthInPercent={matchesQuery ? 95 : 55}
        customButton={
          <St.BtnContainer style={{ display: "flex", gap: "2px" }}>
            <Button
              fitContent
              onClick={() => setDialogIsOpenDialog(false)}
              outlined
              marginBottom={8}
              style={{ padding: "20px" }}
            >
              <span>{"Voltar"}</span>
            </Button>

            <Button
              fitContent
              onClick={() => handleAssinar()}
              outlined
              marginBottom={8}
              disabled={listDepentent.length > 0}
              style={{ padding: "20px" }}
            >
              Assinar adesão
            </Button>
            <Button
              fitContent
              onClick={() => setDialogIsOpenComf(true)}
              marginBottom={8}
              disabled={listDepentent.length <= 0}
              style={{ padding: "20px" }}
            >
              <span>{"Salvar dependente"}</span>
            </Button>
          </St.BtnContainer>
        }
      >
        <ContainerFlex padding={3}>
          <St.DivGroupBox>
            <ContainerFlex marginTop={10} marginLeft={5} maxWidth={20}>
              <Input
                id="name"
                name="userFullName"
                label="Nome completo"
                placeholder=""
                type="text"
                defaultValue={form.nome}
                value={form.nome}
                onChange={(e) => setForm({ ...form, nome: e.target.value })}
                errorDiv={errorValidate.nome.error}
              />
            </ContainerFlex>
            <ContainerFlex marginTop={10} marginLeft={5} maxWidth={20}>
              <Input
                label="Dt nascimento"
                name="birthDate"
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                value={dateMask(form.dataNascimento).masked}
                onChange={(e) =>
                  setForm({ ...form, dataNascimento: e.target.value })
                }
                placeholder="dd/mm/aaaa"
                max={10}
                errorDiv={errorValidate.dataNascimento.error}
              />
            </ContainerFlex>
            <ContainerFlex marginTop={10} marginLeft={5} maxWidth={20}>
              <Input
                name="cpf"
                label="CPF"
                placeholder="000.000.000-00"
                type="text"
                value={cpfMask(form.cpf).masked}
                onChange={(e) => setForm({ ...form, cpf: e.target.value })}
                max={14}
              />
            </ContainerFlex>
            <ContainerFlex marginTop={10} marginLeft={5} maxWidth={20}>
              <Input
                label="Telefone"
                name="phone"
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                value={phoneMask(form.telefone).masked}
                onChange={(e) => setForm({ ...form, telefone: e.target.value })}
                placeholder="(99) 99999-9999"
                max={15}
              />
            </ContainerFlex>
            <ContainerFlex marginTop={10} marginLeft={5} maxWidth={10}>
              <St.BtnPlus onClick={handleClick} />
            </ContainerFlex>
          </St.DivGroupBox>
        </ContainerFlex>
        <ContainerFlex align={"normal"} padding={3}>
          <St.TableTitle>Dependentes incluídos</St.TableTitle>
          <St.Table>
            <tr>
              <th>Nome</th>
              <th>Data Nascimento</th>
              <th>CPF</th>
              <th>Telefone</th>
              <th />
            </tr>
            {listDepentent.map((dependents) => (
              <tr key={dependents.id}>
                <td>{dependents.nome}</td>
                <td>{dateMask(dependents.dataNascimento).masked}</td>
                <td>{cpfMask(dependents.cpf).masked}</td>
                <td>{phoneMask(dependents.telefone).masked}</td>
                <td>
                  <St.BtnMinus onClick={() => handleRemove(dependents)} />
                </td>
              </tr>
            ))}
          </St.Table>
        </ContainerFlex>
      </Dialog>
      <Dialog
        icon={assets.png.tick}
        title={
          <span style={{ color: "red" }}>
            Confirme abaixo a inclusão dos dependentes!
          </span>
        }
        message="Deseja salvar dependentes?"
        open={dialogIsOpenComf}
        negativeLabel="Não"
        negativeAction={() => {
          setDialogIsOpenComf(false);
        }}
        positiveLabel="Sim"
        positiveAction={() => {
          addDependent(listDepentent);
        }}
        widthInPercent={50}
      >
        <ContainerFlex align={"normal"} padding={3}>
          <St.TableDependents>
            <tr>
              <th>Nome</th>
              <th>CPF</th>
              <th>Data Nascimento</th>
            </tr>
            {listDepentent.map((dependents) => (
              <tr key={dependents.id}>
                <td>{dependents.nome}</td>
                <td>{cpfMask(dependents.cpf).masked}</td>
                <td>{dateMask(dependents.dataNascimento).masked}</td>
              </tr>
            ))}
          </St.TableDependents>
        </ContainerFlex>
      </Dialog>
    </>
  );
}
