/* eslint-disable react-hooks/exhaustive-deps */
import assets from "assets/index";
import Button from "components/Button";
import Camera, { FACING_MODES } from "components/NewCamera";
import Grid from "components/Grid";
import Input from "components/Input";
import InputDateSelect from "components/InputDateSelect";
import InputMoney from "components/InputMoney";
import InputRadio from "components/InputRadio";
import InputSelect from "components/InputSelect";
import Layout from "components/Layout";
import OneButtonPopup from "components/modals/OneButtonPopup";
import TwoButtonPopup from "components/modals/TwoButtonPopup";
import PictureModal from "components/PictureCard";
import Spinner from "components/Spinner";
import { EmailContext } from "context/EmailContext";
import { GlobalContext } from "context/GlobalContext";
import * as moment from "moment";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { MdPhotoCamera } from "react-icons/md";
import { useLocation } from "react-router-dom";
import FaceCaptchaService, {
  getCertifaceResultMessage,
  getImageEnc,
} from "../../services/facecaptcha/facecaptcha.js";
import useWebSocket from "react-use-websocket";
import themes from "styles";

import useReactRouter from "use-react-router";
import { transformInNormalDate } from "utils/date";
import { cepMask, dateMask, emailMask, nameMask, phoneMask } from "utils/mask";
import { verifyDisabled } from "utils/verifyDisabled";
import { groups, groupsRef, optionsUfs } from "./constants";
import * as S from "./styles";
import constants from "utils/constants";
import Repository from "repository";
import useApi from "hooks/useApi.js";

const IMAGE_TYPES = {
  CERTIFACE: "CERTIFACE",
  FRENTE: "FRENTE",
  VERSO: "VERSO",
};

const CadComplete = () => {
  const [nome, setNome] = useState();
  const [celular, setCelular] = useState();
  const [celular2, setcelular2] = useState();
  const [cep, setCep] = useState();
  const [logradouro, setLogradouro] = useState();
  const [numero, setNumero] = useState();
  const [complemento, setComplemento] = useState();
  const [cidade, setCidade] = useState();
  const [uf, setUf] = useState();
  const [bairro, setBairro] = useState();
  const [genero, setGenero] = useState();
  const [dataNascimento, setDataNascimento] = useState();
  const [profissao, setProfissao] = useState();
  const [empregoFormal, setEmpregoFormal] = useState("");
  const [aposentadoPensionista, setAposentadoPensionista] = useState();
  const [socialName, setSocialName] = useState("");
  const [nomeSocial, setNomeSocial] = useState("");
  const [empresa, setEmpresa] = useState();
  const [renda, setRenda] = useState();
  const [diaVencimento, setDiaVencimento] = useState();
  const [refName, setRefName] = useState("");
  const [refTel, setRefTel] = useState("");
  const [refObs, setRefObs] = useState("");
  const [refRelacao, setRefRelacao] = useState("");
  const [refNameII, setRefNameII] = useState("");
  const [refTelII, setRefTelII] = useState("");
  const [refObsII, setRefObsII] = useState("");
  const [refRelacaoII, setRefRelacaoII] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [modalOpenValidatePhoto, setModalOpenValidatePhoto] = useState(false);
  const [successProposalPopUp, setSuccessProposalPopUp] = useState(false);
  const [successProposalMessage, setSuccessProposalMessage] = useState(null);
  const [failedProposalPopUp, setFailedProposalPopUp] = useState(false);
  const [failedProposalMessage, setFailedProposalMessage] = useState(false);
  const [errorPopUpUpload, setErrorPopUpUpload] = useState(false);
  const [disableEndereco, setDisableEndereco] = useState(false);
  const [certiface, setCertiface] = useState({});
  const [currentChallenge, setCurrentChallenge] = useState(null);
  const [certifaceMessagebase64, setCertifaceMessagebase64] = useState(null);
  const [certifaceImages, setCertifaceImages] = useState([]);
  const [certifaceErrorMessage, setCertifaceErrorMessage] = useState("");
  const [certifacePopupVisibility, setCertifacePopupVisibility] =
    useState(false);
  const [popUpErrorTelephoneVisibility, setPopUpErrorTelephoneVisibility] =
    useState(false);
  const [
    popUpErrorTelephoneVisibilityMessage,
    setPopUpErrorTelephoneVisibilityMessage,
  ] = useState("");
  const [userPicture, setUserPicture] = useState();
  const [documentsPicture1, setDocumentsPicture1] = useState(null);
  const [documentsPicture2, setDocumentsPicture2] = useState(null);
  const [camUserPictureIsOpen, setCamUserPictureIsOpen] = useState(false);
  const [camUserPictureRepeatIsOpen, setCamUserPictureRepeatIsOpen] =
    useState(false);
  const [camUserPictureIsClose, setCamUserPictureIsClose] = useState(false);
  const [cam1DocumentsIsOpen, setCam1DocumentsIsOpen] = useState(false);
  const [cam2DocumentsIsOpen, setCam2DocumentsIsOpen] = useState(false);
  const [firstPictureCertiface, setFirstPictureCertiface] = useState(null);
  const [errorUserPicture] = useState(null);
  const [imageIsLoading, setImageIsLoading] = useState(false);
  const [lastPictureUploaded, setLastPictureUploaded] = useState();
  const [errorUploadUserPicture, setErrorUploadUserPicture] = useState(false);
  const [errorUploadDocumentFront, setErrorUploadDocumentFront] =
    useState(true);
  const [errorUploadDocumentBack, setErrorUploadDocumentBack] = useState(true);
  const [formErrors, setFormErrors] = useState({});
  const [auxImage, setAuxImage] = useState("");
  const [dataBenefit, setDataBenefit] = useState([]);
  const [benefitList, setBenefitList] = useState([]);
  const [birthdatePopUp, setBirthdatePopUp] = useState(false);
  const [livenessActive] = useState(
    process.env.REACT_APP_LIVENESS_ACTIVE === "true" ? true : false
  );
  const [countSnapNow, setCountSnapNow] = useState(0);
  const [failedProposalSelfMessage, setFailedProposalSelfMessage] =
    useState("");
  const [failedProposalSelfPopUp, setFailedProposalSelfPopUp] = useState(false);

  const { proposta, addProposta, addPropostaCompleta } =
    useContext(GlobalContext);
  const { history } = useReactRouter();
  const { state } = useLocation();
  const { email, setEmail } = useContext(EmailContext);
  const onTakeFirst = useRef();
  const livenessRef = useRef();

  const [webSocketTimeout, setWebsocketTimeout] = useState(59);
  const [socketConnectionOpen, setSocketConnectionOpen] = useState(false);
  const [waitingSocketResponseModal, setWaitingSocketResponseModal] =
    useState(false);

  const origem = Repository.storage.get(Repository.keys.ID_ORIGEM);
  const step = 2;

  let countrSnapEnd = 0;

  const cadObject = {
    nome,
    email,
    celular,
    celular2,
    cep,
    logradouro,
    numero,
    complemento,
    cidade,
    bairro,
    uf,
    genero,
    dataNascimento,
    profissao,
    empresa,
    renda,
    diaVencimento,
    empregoFormal,
    aposentadoPensionista,
    socialName,
    nomeSocial,
    step,
    documentsPicture1,
    documentsPicture2,
    userPicture,
    errorUploadUserPicture,
    errorUploadDocumentFront,
    errorUploadDocumentBack,
    refName,
    refTel,
    refRelacao,
    refNameII,
    refTelII,
    refRelacaoII,
    isMinor: isMinor(dataNascimento?.masked),
  };

  useEffect(() => {
    Repository.session.remove(Repository.keys.CODE_VERIFICATION);
    Repository.session.remove(Repository.keys.X_CODE);
    return () => {
      setRenda(null);
      setCelular(null);
      setcelular2(null);
      setNome(null);
      setCep(null);
      setLogradouro(null);
      setNumero(null);
      setComplemento(null);
      setBairro(null);
      setCidade(null);
      setUf(null);
      setDataNascimento(null);
      setEmail(null);
      setProfissao(null);
      setEmpresa(null);
      addProposta({});
    };
  }, []);

  useEffect(() => {
    if (proposta) {
      setRenda(proposta?.rendaPresumida);
      setCelular(phoneMask(state?.celular)?.masked);
      setcelular2(state?.celular2 || proposta?.celular2);
      setNome(proposta?.nome);
      setCep(cepMask(proposta.endereco?.cep));
      setLogradouro(proposta.endereco?.logradouro);
      setNumero(proposta?.endereco?.numero);
      setComplemento(proposta?.endereco?.complemento);
      setBairro(proposta?.endereco?.bairro);
      setCidade(proposta?.endereco?.cidade);
      setUf(proposta?.endereco?.uf);
      setDataNascimento(transformInNormalDate(proposta?.dataNascimento));
      setEmail(state?.email ? state?.email : proposta?.email);
      setProfissao(state?.profissao ? state?.profissao : proposta?.profissao);
      setEmpresa(state?.nomeEmpresa || proposta?.nomeEmpresa);
      setEmpregoFormal(String(proposta?.empregoFormal));
      setAposentadoPensionista(String(false));
      setSocialName(String(false));
    }
  }, [proposta]);

  const GetBenefit = useApi(constants.endpoints.getBenefit, "GET");
  const getBenefit = async () => {
    if (origem?.tipo !== "LOJA") {
      try {
        setLoading(true);
        const response = await GetBenefit.callApi();

        if (response.status >= 200 && response.status < 300) {
          const onlyOdonto = response?.data?.filter(
            (e) => e.idBeneficioAutorizador === 1
          );
          setDataBenefit(onlyOdonto);
        }
      } catch (error) {
        console.error(error.response.data.mensagem);
        setCertifaceErrorMessage(
          error.response.data.mensagem ||
            "Erro ao carregar informações do benefício."
        );
        setCertifacePopupVisibility(true);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getBenefit();
  }, []);

  function isMinor(birthdateString) {
    var birthdate = new Date(birthdateString?.split("/").reverse().join("-"));

    var today = new Date();
    var age = today.getFullYear() - birthdate.getFullYear();

    if (
      today.getMonth() < birthdate.getMonth() ||
      (today.getMonth() === birthdate.getMonth() &&
        today.getDate() < birthdate.getDate())
    ) {
      age--;
    }
    return age < 18;
  }

  const handleSendForm = () => {
    if (phoneMask(celular)?.unmasked === phoneMask(celular2)?.unmasked) {
      setPopUpErrorTelephoneVisibilityMessage(
        "Número de celular 2 não pode ser igual ao telefone da proposta."
      );
      setPopUpErrorTelephoneVisibility(true);
      return;
    }

    if (
      phoneMask(celular)?.unmasked === refTel?.unmasked ||
      phoneMask(celular)?.unmasked === refTelII?.unmasked
    ) {
      setPopUpErrorTelephoneVisibilityMessage(
        "Número de referência não pode ser igual ao telefone da proposta."
      );
      setPopUpErrorTelephoneVisibility(true);
      return;
    }

    if (isMinor(dataNascimento?.masked)) {
      setCertifaceErrorMessage("Menor de idade, impossível a contratação!");
      setBirthdatePopUp(true);
      return;
    }

    const validatePhoneInputs = Boolean(
      phoneMask(celular2)?.unmasked || refTel?.unmasked || refTelII?.unmasked
    );
    const validatePhonesRules =
      phoneMask(celular2)?.unmasked === refTel?.unmasked ||
      phoneMask(celular2)?.unmasked === refTelII?.unmasked;

    if (validatePhoneInputs && validatePhonesRules) {
      setPopUpErrorTelephoneVisibilityMessage(
        "Número de referência não pode ser igual ao celular 2."
      );
      setPopUpErrorTelephoneVisibility(true);
      return;
    }

    if (validatePhoneInputs && refTel?.unmasked === refTelII?.unmasked) {
      setPopUpErrorTelephoneVisibilityMessage(
        "Número de referência não pode ser igual ao número da outra referência."
      );
      setPopUpErrorTelephoneVisibility(true);
      return;
    }

    if (socialName === "true") {
      if (!nomeSocial?.trim().length) {
        setPopUpErrorTelephoneVisibilityMessage("Nome social não informado.");
        setPopUpErrorTelephoneVisibility(true);
      } else if (nomeSocial.toUpperCase() === nome) {
        setPopUpErrorTelephoneVisibilityMessage(
          "O nome social não pode ser igual ao nome"
        );
        setPopUpErrorTelephoneVisibility(true);
      }
    }

    handleSubmit();
  };

  const PostCreateProposal = useApi(constants.endpoints.createProposal, "POST");

  const completeProposal = async (data) => {
    const res = await PostCreateProposal.callApi(data);

    if (res.status >= 200 && res.status <= 300) {
      if (res.data?.propostaId) {
        Repository.storage.set(
          Repository.keys.EMAIL_PROPOSTA,
          data?.cliente?.email.trim()
        );
      }

      Repository.storage.set(Repository.keys.ID_PROPOSTA, data.propostaId);

      // 20/05/2024 alterado de 'APROVADO' para 'PROCESSANDO' para abrir o socket
      if (res.data.aprovado === "PROCESSANDO") {
        setSocketUrl(
          constants.endpoints.postWebsocketProposal.replace(
            "PARAM_ID_PROPOSTA",
            res.data?.propostaId
          )
        );
        setSocketConnectionOpen(true);
        setWaitingSocketResponseModal(true);
        addPropostaCompleta(res.data);
        setSuccessProposalMessage(res.data);
      } else if (
        res.data.aprovado === "ANALISE" ||
        res.data.aprovado === "PENDENTE"
      ) {
        // aqui vai pra tela propsota-resumo em status de analise
        history.push("/proposta-resumo", {
          status: "ANALISE",
        });
      } else if (res.data.aprovado === "REPROVADO") {
        setFailedProposalMessage("Proposta recusada!");
        setFailedProposalPopUp(true);
      }
    } else if (res?.data?.selfieNaoEncontrada) {
      // **********************
      // ******
      // ***
      // Tentativa de resolver o problema de ficar sem self.
      // Caso nao encontre a self no backend sera retornado um erro.
      // Com esse erro devemos limpar as info da self e permitir o
      // usuario fazer novamente.
      //
      // Response com erro Ex:
      // {
      //   "mensagem": "A selfie não foi armazenada, por favor tire a selfie novamente.",
      //   "status": 404,
      //   "selfieNaoEncontrada": true
      // }
      // *********************
      // **********
      // ***
      setFailedProposalSelfMessage(res.mensagem);
      setUserPicture();
      setCertiface({});
      setFailedProposalSelfPopUp(true);
    } else if (res?.data?.campoInvalido) {
      setFailedProposalSelfMessage(res.mensagem);
      setFailedProposalSelfPopUp(true);
    } else {
      setFailedProposalMessage(res.mensagem);
      setFailedProposalPopUp(true);
    }
  };

  const handleSubmit = async () => {
    if (!handleFormValidation()) return;

    setLoading(true);

    const newDataNascimento = moment(
      dataNascimento?.unmasked,
      "DD/MM/YYYY"
    ).format("YYYY-MM-DD");

    try {
      await completeProposal({
        cliente: {
          cpf: state.cpf,
          celular: phoneMask(celular).unmasked,
          celular2: phoneMask(celular2).unmasked,
          dataNascimento: newDataNascimento,
          diaVencimento,
          email: email.trim(),
          nome: nome.trim().replace(/ +(?= )/g, ""),
          nomeSocial: socialName === "true" ? nomeSocial : "",
          endereco: {
            bairro,
            cep: cep?.unmasked,
            cidade,
            complemento,
            logradouro,
            numero,
            pais: "BRASIL",
            uf,
          },
          genero,
          limiteCredito: 5000,
          profissao: empregoFormal === "true" ? profissao : "",
        },
        contatoRequest: [
          {
            comentario: refObs.trim(),
            nome: refName.trim(),
            relacao: refRelacao.trim(),
            telefone: refTel?.unmasked.trim(),
          },
          {
            comentario: refObsII.trim(),
            nome: refNameII.trim(),
            relacao: refRelacaoII.trim(),
            telefone: refTelII?.unmasked.trim(),
          },
        ],
        contaConsumo: 0,
        cpfAnalisadoMenosNoventaDias: false,
        rendaInformada: renda,
        negativacao: proposta.negativacao,
        propostaId: proposta.id,
        rendaPresumida: proposta.rendaPresumida,
        score: proposta.score,
        empregoFormal: empregoFormal === "true" ? true : false,
        aposentadoPensionista: aposentadoPensionista === "true" ? true : false,
        nomeEmpresa: empresa,
        idBeneficios: benefitList,
        temBeneficio: benefitList.length > 0 ? true : false,
        certiface: livenessActive
          ? certiface
          : {
              codID: 1.1,
              valid: true,
              cause: "CERTIFACE",
              protocol: "CERTIFACE",
              score: 99.9,
            },
      });
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleFormValidation = () => {
    const formErrors = verifyDisabled(cadObject);
    setFormErrors(formErrors);

    const hasErrors = Object.keys(formErrors).length >= 1;

    if (hasErrors) return false;
    else return true;
  };

  const PostClientIdentification = useApi(
    constants.endpoints.saveClientIdentification,
    "POST"
  );

  /**
   * Funcao responsavel por chamr servico no back para gravar a imagem.
   *
   *
   * @param image - Imagem em Base64!
   * @param type - Indica o tipo da imagem que sera salva
   */
  const handleUploadPicture = async (image, type) => {
    setImageIsLoading(type);
    setAuxImage(image);
    try {
      const response = await PostClientIdentification.callApi({
        file: image.split(",")[1],
        tipo: type,
        idProposta: proposta.id,
      });

      switch (type) {
        case IMAGE_TYPES.CERTIFACE:
          setErrorUploadUserPicture(false);
          setUserPicture(firstPictureCertiface);
          break;
        case IMAGE_TYPES.FRENTE:
          setErrorUploadDocumentFront(false);
          setDocumentsPicture1(image);
          break;
        case IMAGE_TYPES.VERSO:
          setErrorUploadDocumentBack(false);
          setDocumentsPicture2(image);
          break;
        default:
          return;
      }

      if (response.status === 404) {
        setErrorPopUpUpload(true);

        switch (type) {
          case IMAGE_TYPES.CERTIFACE:
            setErrorUploadUserPicture(true);
            setUserPicture();
            setCertiface({});
            break;
          case IMAGE_TYPES.FRENTE:
            setErrorUploadDocumentFront(true);
            setDocumentsPicture1(null);
            break;
          case IMAGE_TYPES.VERSO:
            setErrorUploadDocumentBack(true);
            setDocumentsPicture2(null);
            break;
          default:
            return;
        }
      }
    } catch (err) {
      switch (type) {
        case IMAGE_TYPES.CERTIFACE:
          setErrorUploadUserPicture(true);
          setUserPicture();
          setCertiface({});
          break;
        case IMAGE_TYPES.FRENTE:
          setErrorUploadDocumentFront(true);
          setDocumentsPicture1(null);
          break;
        case IMAGE_TYPES.VERSO:
          setErrorUploadDocumentBack(true);
          setDocumentsPicture2(null);
          break;
        default:
          return;
      }

      setErrorPopUpUpload(true);
    } finally {
      setImageIsLoading(null);
    }
  };

  const onClickDeleteDocumentPicture = (index) => {
    if (index === 1) {
      setDocumentsPicture1(null);
    } else {
      setDocumentsPicture2(null);
    }
  };

  const toggleCameraUserPicture = async () => {
    const isFormValid = checkIfLivenessFormValid();

    if (!isFormValid.valid) {
      setCertifaceErrorMessage(
        isFormValid.field + " obrigatório para iniciar a Prova de Vida"
      );
      setCertifacePopupVisibility(true);
      return;
    }
    countrSnapEnd = 0;
    setCamUserPictureIsClose(false);

    // startLiveness();

    setCamUserPictureIsOpen((prev) => !prev);
  };

  /**
   * Responsavel por tirar a foto de acordo com o timer passado.
   *
   * @param {*} time - Recebe a quantidade de tempo
   * @param {*} obj - Recebe o Objeto retornado e descriptografado
   */
  const forceCameraTakePicture = (time, obj) => {
    setTimeout(() => {
      let count = 0;
      setCurrentChallenge(obj);
      setCertifaceMessagebase64(obj.message);
      const image = setInterval(async () => {
        count++;

        if (
          count === obj.numberSnap &&
          obj.numberOfChallenges - 1 === countrSnapEnd
        ) {
          setCamUserPictureIsClose(true);
        }

        if (livenessRef.current) {
          await livenessRef.current.takePicture();
        }
      }, 4000);

      const challenge = setInterval(function () {
        clearInterval(image);
        clearInterval(challenge);
        countrSnapEnd++;
      }, time);
    }, (time + 1000) * obj.index);
  };

  const checkIfLivenessFormValid = () => {
    if (!nome) return { field: "Nome Completo ", valid: false };
    if (!dataNascimento?.masked)
      return { field: "Data de Nascimento ", valid: false };

    return { field: "", valid: true };
  };

  /**
   * Iniciar o liveness.
   * Busca os desafios.
   */
  const startLiveness = async () => {
    setImageIsLoading(IMAGE_TYPES.CERTIFACE);
    const service = FaceCaptchaService();
    setCertifaceImages([]);

    const startResponse = await service.start(
      nome || "",
      dataNascimento?.masked || "00/00/0000",
      state.cpf || ""
    );

    // setCamUserPictureIsOpen((prev) => !prev);

    if (startResponse.status === 200) {
      const challenges = startResponse.data?.challenge?.challenges || [];

      if (challenges.length > 0) {
        await challenges.forEach(async (ch, index) => {
          const code = ch.tipoFace.codigo;
          const time = ch.tempoEmSegundos * 1000;
          const size = challenges.length - 1;
          const totalTime = startResponse.data?.challenge?.totalTime;
          const snapInMillis =
            startResponse.data?.challenge?.snapFrequenceInMillis;
          const numberSnap = startResponse.data?.challenge?.snapNumber;
          const numberOfChallenges =
            startResponse.data?.challenge?.numberOfChallenges;

          forceCameraTakePicture(time, {
            code,
            time,
            index,
            size,
            message: ch.mensagem,
            startResponse,
            totalTime,
            snapInMillis,
            numberSnap,
            numberOfChallenges,
          });
        });
      }
    }
    setImageIsLoading(null);
  };

  /**
   * Funcao chamada apos a captura da imagem.
   *
   * @param {*} image - Imagem capturada pela camera
   */
  const invokeCameraHandle = (image) => {
    if (currentChallenge === null) return;

    const { code, index, size, message, startResponse } = currentChallenge;

    // Ou seja so sera armazenado em nosso BD a imagem do primeiro desafio referente ao segundo Snap.
    if (index === 0 && countSnapNow === 0) {
      onTakeFirst.current = image;
      setFirstPictureCertiface(image);
    }

    setCountSnapNow(countSnapNow + 1);

    const imageBase64 = image;

    const currentImages = certifaceImages;

    const newImage = getImageEnc(imageBase64, code);

    setCertifaceMessagebase64(message);

    currentImages.push(newImage);
    setCertifaceImages(currentImages);

    if (index === size && camUserPictureIsClose) {
      if (livenessRef.current) {
        livenessRef.current.stopCamera();
      }

      if (index === 0) {
        sendChallenges(startResponse, currentImages, imageBase64);
      } else {
        sendChallenges(startResponse, currentImages);
      }
      setCurrentChallenge(null);
      setCertifaceMessagebase64(null);
    }
  };

  /**
   * Fica responsavel por chama a validacao da oititec.
   *
   * service.captcha - servico que faz a validacao dos desafios.
   *
   * @param {*} startResponse - obj montado no inicio do liveness
   * @param {*} images - Imagens a ser passadas pela validacao do liveness
   * @param {*} firstImage  - Primeira imagem capturada pela camera no fluxo do liveness
   */
  const sendChallenges = async (startResponse, images, firstImage) => {
    setImageIsLoading(IMAGE_TYPES.CERTIFACE);

    const service = FaceCaptchaService();

    const challengeResponse = await service.captcha(startResponse, images);

    setImageIsLoading(null);

    setCertiface(challengeResponse.data?.result);

    setCertifaceErrorMessage("");

    if (challengeResponse.success === true) {
      const codeResult = challengeResponse.data.result.codID;
      if ([1.1, 1.2].includes(codeResult)) {
        // Com o sucesso do liveness chamo a modal para validar se a foto esta boa!
        setUserPicture(firstPictureCertiface);
        setModalOpenValidatePhoto(true);
      } else if ([200.1, 200.2].includes(codeResult)) {
        setUserPicture(firstPictureCertiface);
      } else {
        setCertifaceErrorMessage(
          getCertifaceResultMessage(challengeResponse.data.result.codID || 0)
        );
        setCertifacePopupVisibility(true);
      }
    }
  };

  const toggleCameraDocuments = (index) => {
    if (index === 1) {
      setCam1DocumentsIsOpen((prev) => !prev);
    } else {
      setCam2DocumentsIsOpen((prev) => !prev);
    }
  };

  /**
   * Funcao para fluxo de captura de documento (Frente e verso).
   *
   *
   * @param imageBASE64 - responsavel pela imagem em Base64!
   * @param index - Quando for === 1 é a frente do documento
   */
  const onTakeDocumentPicture = async (imageBASE64, index) => {
    if (index === 1) {
      setLastPictureUploaded(IMAGE_TYPES.FRENTE);
      await handleUploadPicture(imageBASE64, IMAGE_TYPES.FRENTE);
    } else {
      setLastPictureUploaded(IMAGE_TYPES.VERSO);
      await handleUploadPicture(imageBASE64, IMAGE_TYPES.VERSO);
    }
  };
  const GetAddressByCep = useApi(
    constants.endpoints.findAddressByCep.replace("PARAM_CEP", cep?.unmasked),
    "GET"
  );

  useEffect(() => {
    (async () => {
      if (cep?.unmasked.length === 8) {
        const response = await GetAddressByCep.callApi();
        if (response.status >= 200 && response.status <= 300) {
          setDisableEndereco(false);
          setLogradouro(response.data?.logradouro);
          setBairro(response.data?.bairro);
          setCidade(response.data?.localidade);
          setUf(response.data?.uf);
        }
      }
    })();
  }, [cep]);

  async function handleCep(e) {
    const valor = e.target.value;
    setCep(cepMask(valor));
  }

  // Isso e necessario para validar a imagem junto ao CERTIFACE!!!
  const cameraButtonIsDisabled = useMemo(
    () =>
      !nome?.trim().length ||
      dataNascimento.masked?.length !== 10 ||
      certiface?.codID === 1.1 ||
      certiface?.codID === 1.2 ||
      certiface?.codID === 200.1 ||
      certiface?.codID === 200.2 ||
      imageIsLoading === IMAGE_TYPES.CERTIFACE,
    [nome, dataNascimento, certiface, imageIsLoading]
  );

  const buttonIsDisabled = () =>
    (certiface?.codID !== 1.1 &&
      certiface?.codID !== 1.2 &&
      certiface?.codID !== 200.1 &&
      certiface?.codID !== 200.2) ||
    isLoading;

  const GetBenefitById = useApi(
    constants.endpoints.getBenefitById.replace(
      "PARAM_ID_PROPOSAL",
      successProposalMessage?.propostaId
    ),
    "GET"
  );
  const GetBenefitNotContractedById = useApi(
    constants.endpoints.getBenefitNotContractedByIdProposal.replace(
      "PARAM_ID_PROPOSAL",
      successProposalMessage?.propostaId
    ),
    "GET"
  );
  // essa funcao vai ser chamada caso o socket retorne status APROVADA
  const handleChecksBenefit = async (id) => {
    try {
      const response = await GetBenefitById.callApi();
      const benefitNotContracted = await GetBenefitNotContractedById.callApi();
      const newDataNascimento = moment(
        dataNascimento?.unmasked,
        "DD/MM/YYYY"
      ).format("YYYY-MM-DD");
      const dateProposta = new Date(newDataNascimento)?.getFullYear();
      const dateNow = new Date()?.getFullYear();
      const isUserMoreThan65YearsOld = dateNow - dateProposta > 65;
      if (response.status === 200) {
        if (response.data.beneficio) {
          history.push("/proposta", {
            propostaId: id,
          });
        } else {
          //recebeu socket aprovado vai pra tela de detalhes e depois pra de beneficios
          history.push("/proposta-resumo", {
            propostaId: id,
            isUserMoreThan65YearsOld,
            cpf: state.cpf,
            benefits: {
              contracted:
                response?.data?.beneficios || response?.data?.beneficio,
              notContracted: benefitNotContracted?.data,
            },
            nome: lastJsonMessage?.nomeCliente,
            email: lastJsonMessage?.email,
            vencimento: lastJsonMessage?.diaVencimento,
            limiteCompras: lastJsonMessage?.limiteCredito,
            limiteSaque: lastJsonMessage?.limiteSaqueConcedido,
            status: "APROVADA",
          });
        }
      }
    } catch (err) {
      console.error(err.response);
    }
  };

  useEffect(() => {
    let timer;
    if (socketConnectionOpen) {
      if (webSocketTimeout === 0) {
        history.push("/proposta-resumo", { status: "ANALISE" });

        return;
      }
      timer = setTimeout(() => {
        setWebsocketTimeout((prev) => prev - 1);
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [socketConnectionOpen, webSocketTimeout]);

  const [socketUrl, setSocketUrl] = useState("");

  const { readyState, lastJsonMessage } = useWebSocket(
    socketUrl,
    {},
    socketConnectionOpen
  );

  useEffect(() => {
    if (!!lastJsonMessage && readyState === 1) {
      if (lastJsonMessage?.aprovado === "APROVADA") {
        handleChecksBenefit(lastJsonMessage?.propostaId);
      }
    }
  }, [lastJsonMessage, readyState]);

  return (
    <Layout.View
      host={state?.idAnfitriao ? state?.idAnfitriao : ""}
      title={origem?.tipo !== "LOJA" ? "Proposta Teucard" : "Dados pessoais"}
      routeBack="/opcoes-acesso"
    >
      <OneButtonPopup
        defaultButton
        text={certifaceErrorMessage}
        buttonText="OK"
        visibility={certifacePopupVisibility}
        setVisibility={() => {
          setCertifacePopupVisibility(false);
        }}
      />

      <OneButtonPopup
        defaultButton
        text={certifaceErrorMessage}
        buttonText="OK"
        visibility={birthdatePopUp}
        setVisibility={() => {
          setBirthdatePopUp(false);
        }}
      />

      <OneButtonPopup
        defaultButton
        text={popUpErrorTelephoneVisibilityMessage}
        buttonText="Entendi"
        visibility={popUpErrorTelephoneVisibility}
        setVisibility={() => {
          setPopUpErrorTelephoneVisibility(false);
        }}
      />

      <TwoButtonPopup
        width={50}
        text="Esta foto ficou boa?"
        visibility={modalOpenValidatePhoto}
        buttonCancelText="Repetir foto"
        buttonText="Confirmar"
        onClick={() => {
          // Continua fluxo ja existente (Insere a foto no backEnd).
          setModalOpenValidatePhoto(false);
          handleUploadPicture(firstPictureCertiface, IMAGE_TYPES.CERTIFACE);
        }}
        onClickCancel={() => {
          // Chama o fluxo para repetir a foto!
          setModalOpenValidatePhoto(false);
          setCamUserPictureRepeatIsOpen(true);
          setFirstPictureCertiface(null);
          setUserPicture(null);
        }}
        setVisibility={setModalOpenValidatePhoto}
        closeOnDocumentClick={false}
      >
        <S.DivModalValidPhoto>
          <PictureModal
            id="picture-modal"
            source={userPicture}
            width="410"
            height="410"
            expand
          />
        </S.DivModalValidPhoto>
      </TwoButtonPopup>

      <Grid xs={1} sm={1} md={1} lg={2} gap={16}>
        <section>
          <Layout.Label>
            Preencha os campos abaixo com os seus dados pessoais, por favor.
          </Layout.Label>

          <br />
          <br />

          <S.Picture>
            {userPicture && (
              <div>
                <PictureModal
                  id="picture-modal"
                  source={firstPictureCertiface || userPicture}
                  width="150"
                  height="150"
                  expand
                />
              </div>
            )}

            <Button
              buttonType="primary"
              onClick={toggleCameraUserPicture}
              disabled={cameraButtonIsDisabled}
            >
              Tirar selfie &nbsp;
              <MdPhotoCamera />
              {imageIsLoading === IMAGE_TYPES.CERTIFACE && (
                <Spinner color="#FFF" />
              )}
            </Button>

            <S.ErrorMessage>{formErrors.userPicture}</S.ErrorMessage>

            <S.WrapperCamera show={camUserPictureIsOpen}>
              <Camera
                open={camUserPictureIsOpen}
                facingMode={
                  origem?.tipo === "LOJA"
                    ? FACING_MODES.ENVIRONMENT
                    : FACING_MODES.USER
                }
                onTakePhoto={invokeCameraHandle}
                isStartLiveness={startLiveness}
                certifaceMessagebase64={certifaceMessagebase64}
                ref={livenessRef}
                handleClose={() => setCamUserPictureIsOpen(false)}
                isLiveness
                stopWatch
                doNotCloseAfterTakePicture
                disableCloseButton
                disableTakePictureButton
                mask
              />
            </S.WrapperCamera>

            {/* Fluxo para repetir a foto, quando a mesma nao fica boa. */}
            <S.WrapperCamera show={camUserPictureRepeatIsOpen}>
              <Camera
                open={camUserPictureRepeatIsOpen}
                onTakePhoto={(img) => {
                  // Chamo a modal para validar se a foto esta boa!
                  setUserPicture(img);
                  setFirstPictureCertiface(img);
                  setModalOpenValidatePhoto(true);
                }}
                handleClose={() => setCamUserPictureRepeatIsOpen(false)}
                mask
              />
            </S.WrapperCamera>
            {errorUserPicture && (
              <S.ErrorMessage>{errorUserPicture}</S.ErrorMessage>
            )}
          </S.Picture>

          {certiface?.codID !== 1.1 && certiface?.codID !== 200.1 && (
            <S.ErrorMessage>{certifaceErrorMessage}</S.ErrorMessage>
          )}

          <br />

          <Layout.Label>
            Agora, tire uma foto do seu documento de identificação. Exemplo: RG
            ou CNH (frente/verso).
          </Layout.Label>

          <br />
          <br />

          <S.Picture>
            <div>
              <S.Text style={{ display: "inline-block", marginLeft: 60 }}>
                Frente
              </S.Text>
              <PictureModal
                id="picture-modal"
                source={documentsPicture1 || assets.png.rgFront}
                width="171"
                height="111"
                onDelete={
                  documentsPicture1
                    ? () => onClickDeleteDocumentPicture(1)
                    : null
                }
                expand={!!documentsPicture1}
                onClickCamera={() => toggleCameraDocuments(1)}
                showOptions={!documentsPicture1}
                isLoading={imageIsLoading === IMAGE_TYPES.FRENTE}
              />

              <S.WrapperCamera show={cam1DocumentsIsOpen}>
                <Camera
                  open={cam1DocumentsIsOpen}
                  onTakePhoto={(img) => onTakeDocumentPicture(img, 1)}
                  handleClose={() => toggleCameraDocuments(1)}
                  maskDocument
                />
              </S.WrapperCamera>
            </div>

            <div>
              <S.Text style={{ display: "inline-block", marginLeft: 65 }}>
                Verso
              </S.Text>
              <PictureModal
                id="picture-modal"
                source={documentsPicture2 || assets.png.rgBack}
                width="171"
                height="111"
                onDelete={
                  documentsPicture2
                    ? () => onClickDeleteDocumentPicture(2)
                    : null
                }
                expand={!!documentsPicture2}
                onClickCamera={() => toggleCameraDocuments(2)}
                showOptions={!documentsPicture2}
                isLoading={imageIsLoading === IMAGE_TYPES.VERSO}
              />
              <S.WrapperCamera show={cam2DocumentsIsOpen}>
                <Camera
                  open={cam2DocumentsIsOpen}
                  onTakePhoto={(img) => onTakeDocumentPicture(img, 2)}
                  handleClose={() => toggleCameraDocuments(2)}
                  maskDocument
                />
              </S.WrapperCamera>
            </div>
          </S.Picture>

          <S.ErrorMessage>{formErrors.documentsPicture}</S.ErrorMessage>
          <br />

          <Input
            id="userFullName"
            name="userFullName"
            label="Nome completo"
            placeholder=""
            type="text"
            defaultValue={nome}
            value={nome}
            onChange={(e) => setNome(nameMask(e.target.value))}
            error={formErrors.nome}
          />

          <div style={{ display: "flex" }}>
            <div style={{ width: "37%" }}>
              <S.Text>Possui Nome Social?</S.Text>
              <S.RadioGroup
                name="socialName"
                id="socialName"
                value={socialName}
                onBlur={() => verifyDisabled(cadObject)}
                onChange={(e) => setSocialName(e.target.value)}
              >
                <InputRadio
                  name="socialName"
                  id="socialNameYes"
                  type="radio"
                  label="Sim"
                  value="true"
                  checked={socialName === "true"}
                />

                <InputRadio
                  name="socialName"
                  id="socialNameNo"
                  type="radio"
                  label="Não"
                  value="false"
                  checked={socialName === "false"}
                />
              </S.RadioGroup>
            </div>
            <div style={{ marginTop: "38px", width: "100%" }}>
              <Input
                name="socialName"
                id="socialName"
                value={socialName === "true" ? nomeSocial : ""}
                disabled={socialName === "false" ? true : false}
                onChange={(e) => setNomeSocial(e.target.value)}
                error={formErrors.nomeSocial}
              />
            </div>
          </div>

          <S.ErrorMessage>{formErrors.socialName}</S.ErrorMessage>

          <Input
            id="email"
            name="email"
            label="E-mail"
            type="text"
            value={email}
            placeholder="exemplo@exemplo.com.br"
            onChange={(e) => setEmail(emailMask(e.target.value))}
            error={formErrors.email}
          />

          <Input
            name="celular"
            id="celular"
            label="Celular 1"
            type="text"
            defaultValue={celular}
            value={phoneMask(celular)?.masked}
            max={15}
            onChange={(e) => setCelular(phoneMask(e.target.value).masked)}
            error={formErrors.celular}
            pattern="[0-9]*"
            inputmode="numeric"
          />

          <Input
            name="celular2"
            id="celular2"
            label="Celular 2"
            type="text"
            defaultValue={celular2}
            value={phoneMask(celular2)?.masked}
            max={15}
            onChange={(e) => setcelular2(phoneMask(e.target.value).masked)}
            error={formErrors.celular2}
            pattern="[0-9]*"
            inputmode="numeric"
          />
        </section>

        <section>
          <Input
            name="birthDate"
            id="birthDate"
            label="Data de nascimento"
            placeholder="dd/mm/aaaa"
            value={dataNascimento?.masked}
            max={10}
            onChange={(e) => setDataNascimento(dateMask(e.target.value))}
            type="number"
            pattern="[0-9]*"
            inputmode="numeric"
            error={formErrors.dataNascimento}
          />
          <InputMoney
            id="income"
            label="Renda estimada"
            value={(renda || 0).toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
              style: "currency",
              currency: "BRL",
            })}
            onChange={(e) =>
              setRenda(
                parseFloat(
                  String(parseInt(e.target.value.replace(/\D+/g, "")))
                    .padStart(3, 0)
                    .split("")
                    .reverse()
                    .map((e, i) => (i === 2 ? e + "." : e))
                    .reverse()
                    .join("")
                )
              )
            }
          />
          <InputDateSelect
            name="invoiceExpiration"
            id="invoiceExpiration"
            type="select"
            value={diaVencimento}
            onChange={(e) => setDiaVencimento(e.target.value)}
            error={formErrors.diaVencimento}
          />
          <Grid xs={1} md={2} sm={2} gap={8}>
            <div>
              <S.Text name={"text1"}>Aposentado ou Pensionista?</S.Text>
              <S.RadioGroup
                name="aposentadoPensionista"
                id="aposentadoPensionista"
                value={aposentadoPensionista}
                onblur={() => verifyDisabled(cadObject)}
                onChange={(e) => setAposentadoPensionista(e.target.value)}
                style={{ margin: 0 }}
              >
                <InputRadio
                  name="aposentadoPensionista"
                  id="aposentandoPensionistaYes"
                  type="radio"
                  label="Sim"
                  value="true"
                  checked={aposentadoPensionista === "true"}
                />

                <InputRadio
                  name="aposentadoPensionista"
                  id="aposentandoPensionistaNot"
                  label="Não"
                  value="false"
                  checked={aposentadoPensionista === "false"}
                />
              </S.RadioGroup>
            </div>
            <div>
              <S.Text name={"text1"}>Possui emprego formal?</S.Text>
              <S.RadioGroup
                name="formalJob"
                id="formalJob"
                value={empregoFormal}
                onblur={() => verifyDisabled(cadObject)}
                onChange={(e) => setEmpregoFormal(e.target.value)}
                style={{ margin: 0 }}
              >
                <InputRadio
                  name="formalJob"
                  id="formalJobYes"
                  type="radio"
                  label="Sim"
                  value="true"
                  checked={empregoFormal === "true"}
                />

                <InputRadio
                  name="formalJob"
                  id="formalJobNot"
                  label="Não"
                  value="false"
                  checked={empregoFormal === "false"}
                />
              </S.RadioGroup>
            </div>
          </Grid>
          <br />
          <S.ErrorMessage>{formErrors.empregoFormal}</S.ErrorMessage>
          <S.ErrorMessage>{formErrors.aposentadoPensionista}</S.ErrorMessage>

          <Input
            name="career"
            id="career"
            label="Profissão"
            value={empregoFormal === "true" ? profissao : ""}
            disabled={empregoFormal === "false" ? true : false}
            onChange={(e) => setProfissao(e.target.value)}
            error={formErrors.profissao}
            style={{ marginTop: "7px" }}
          />

          <Input
            name="company"
            id="company"
            label="Nome da Empresa/Órgão onde trabalha"
            value={empresa}
            defaultValue={empresa}
            type="text"
            onChange={(e) => setEmpresa(e.target.value)}
            error={formErrors.empresa}
            style={{ marginTop: "7px" }}
          />

          <InputSelect
            name="gender"
            id="gender"
            label="Sexo"
            type="select"
            value={genero}
            values={groups}
            onChange={(e) => setGenero(e.target.value)}
            error={formErrors.genero}
          />

          <Input
            name="zipCode"
            id="zipCode"
            label="CEP"
            defaultValue={cep?.masked}
            value={cep?.masked}
            placeholder="00000-000"
            max={8}
            onChange={handleCep}
            type="number"
            pattern="[0-9]*"
            inputmode="numeric"
            error={formErrors.cep}
          />
          <Input
            name="address"
            id="address"
            label="Endereço"
            type="text"
            defaultValue={logradouro}
            value={logradouro}
            onChange={(e) => setLogradouro(e.target.value)}
            disabled={disableEndereco}
            error={formErrors.logradouro}
          />
          <Grid xs={1} md={2} sm={2} gap={8}>
            <div>
              <Input
                name="number"
                id="number"
                label="Número"
                defaultValue={numero}
                value={numero}
                onChange={(e) => setNumero(e.target.value)}
                disabled={disableEndereco}
                type="number"
                pattern="[0-9]*"
                inputmode="numeric"
                error={formErrors.numero}
              />
            </div>

            <div>
              <Input
                name="complement"
                id="complement"
                label="Complemento (opcional)"
                type="text"
                defaultValue={complemento}
                value={complemento}
                onChange={(e) => setComplemento(e.target.value)}
                disabled={disableEndereco}
                error={formErrors.complemento}
              />
            </div>
          </Grid>
          <Grid xs={1} md={2} sm={2} gap={8}>
            <div>
              <Input
                name="neighborhood"
                id="neighborhood"
                label="Bairro"
                type="text"
                defaultValue={bairro}
                value={bairro}
                onChange={(e) => setBairro(e.target.value)}
                disabled={disableEndereco}
                error={formErrors.bairro}
              />
            </div>

            <div>
              <Input
                name="city"
                id="city"
                label="Cidade"
                type="text"
                defaultValue={cidade}
                value={cidade}
                onChange={(e) => setCidade(e.target.value)}
                disabled={disableEndereco}
                error={formErrors.cidade}
              />
            </div>
          </Grid>
          <div>
            <InputSelect
              name="uf"
              id="uf"
              label="UF"
              type="select"
              value={uf}
              defaultValue={uf}
              values={optionsUfs}
              onChange={(e) => setUf(e.target.value)}
              disabled={disableEndereco}
              error={formErrors.uf}
            />
          </div>
        </section>
      </Grid>

      <br />
      <br />
      <Grid xs={1} sm={1} md={1} lg={2} gap={16}>
        <div>
          <Layout.Title>Referência I</Layout.Title>

          <Input
            name="refName"
            id="refName"
            label="Nome"
            type="text"
            defaultValue={refName}
            value={refName}
            onChange={(e) => setRefName(e.target.value)}
            error={formErrors.refName}
          />
          <Input
            name="refTel"
            id="refTel"
            label="Telefone"
            type="text"
            defaultValue={refTel}
            value={refTel?.masked}
            max={15}
            onChange={(e) => setRefTel(phoneMask(e.target.value))}
            error={formErrors.refTel}
            pattern="[0-9]*"
            inputmode="numeric"
          />
          <InputSelect
            name="refRelacao"
            id="refRelacao"
            label="Grau de parentesco/relacionamento"
            type="select"
            value={refRelacao}
            defaultValue={refRelacao}
            values={groupsRef}
            onChange={(e) => setRefRelacao(e.target.value)}
            disabled={disableEndereco}
            error={formErrors.refRelacao}
          />
          <Input
            name="refObs"
            id="refObs"
            label="Comentário (opcional)"
            type="text"
            defaultValue={refObs}
            value={refObs}
            onChange={(e) => setRefObs(e.target.value)}
          />
        </div>
        <div>
          <Layout.Title>Referência II</Layout.Title>

          <Input
            name="refNameII"
            id="refNameII"
            label="Nome"
            type="text"
            defaultValue={refNameII}
            value={refNameII}
            onChange={(e) => setRefNameII(e.target.value)}
            error={formErrors.refNameII}
          />
          <Input
            name="refTelII"
            id="refTelII"
            label="Telefone"
            type="text"
            defaultValue={refTelII}
            value={refTelII?.masked}
            max={15}
            onChange={(e) => setRefTelII(phoneMask(e.target.value))}
            error={formErrors.refTelII}
            pattern="[0-9]*"
            inputmode="numeric"
          />
          <InputSelect
            name="refRelacaoII"
            id="refRelacaoII"
            label="Grau de parentesco/relacionamento"
            type="select"
            value={refRelacaoII}
            defaultValue={refRelacaoII}
            values={groupsRef}
            onChange={(e) => setRefRelacaoII(e.target.value)}
            disabled={disableEndereco}
            error={formErrors.refRelacaoII}
          />
          <Input
            name="refObsII"
            id="refObsII"
            label="Comentário (opcional)"
            type="text"
            defaultValue={refObsII}
            value={refObsII}
            onChange={(e) => setRefObsII(e.target.value)}
          />
        </div>
      </Grid>
      <br />
      <br />

      <Grid xs={1} sm={1} md={2}>
        <div />
        <Button
          buttonType="primary"
          text={isLoading === true ? <Spinner color="#FFF" /> : "Continuar"}
          onClick={() => handleSendForm()}
          id="button-submit"
          disabled={livenessActive ? buttonIsDisabled() : isLoading}
        />

        <OneButtonPopup
          defaultButton
          text={"Parabéns! Proposta aprovada"}
          buttonText="Entendi"
          visibility={successProposalPopUp}
          setVisibility={(val) => {
            setSuccessProposalPopUp(val);
            if (origem.tipo === "LOJA") {
              handleChecksBenefit(successProposalMessage?.propostaId);
            } else {
              window.location = "https://useteucard.com.br/";
            }
          }}
        >
          <>
            <p>
              Status: {successProposalMessage && successProposalMessage?.status}
            </p>
            <p>
              Nome do cliente:{" "}
              {successProposalMessage && successProposalMessage?.nomeCliente}
            </p>
            <p>
              E-mail: {successProposalMessage && successProposalMessage?.email}
            </p>
            <p>
              Dia do vencimento da fatura:{" "}
              {successProposalMessage && successProposalMessage?.diaVencimento}
            </p>
            <p>
              Limite de crédito total:{" "}
              {`R$ ${
                successProposalMessage &&
                Number(successProposalMessage?.limiteCredito) + 100
              }`}
            </p>
            <p>
              Limite de crédito para compra:{" "}
              {`R$ ${
                successProposalMessage && successProposalMessage?.limiteCredito
              }`}
            </p>
            <p>Limite de crédito para saque: {`R$ 100,00`}</p>
          </>
        </OneButtonPopup>

        <OneButtonPopup
          defaultButton
          text={failedProposalMessage}
          buttonText="Entendi"
          visibility={failedProposalPopUp}
          setVisibility={(val) => {
            setFailedProposalPopUp(val);
            if (origem.tipo === "LOJA" && nome === "CADASTRO NAO LOCALIZADO") {
              setFailedProposalPopUp(val);
            } else if (origem.tipo === "LOJA") {
              history.push("/cadastro-minimo");
            } else {
              window.location = "https://useteucard.com.br/";
            }
          }}
        />

        <OneButtonPopup
          defaultButton
          text={failedProposalSelfMessage}
          buttonText="Entendi"
          visibility={failedProposalSelfPopUp}
          setVisibility={(val) => {
            setFailedProposalSelfPopUp(val);
          }}
        />

        <TwoButtonPopup
          text="Erro ao fazer upload das imagens!"
          visibility={errorPopUpUpload}
          buttonText="Tentar novamente"
          onClick={() => setErrorPopUpUpload(false)}
        />

        <S.CustomPopup
          nested
          modal
          onClose={() => setWaitingSocketResponseModal(false)}
          open={waitingSocketResponseModal}
          position={"center center"}
          overlayStyle={{
            background: "rgba(0, 0, 0, 0.7)",
          }}
          closeOnDocumentClick={false}
          contentStyle={{ background: "white", width: "30%" }}
        >
          <div className="container">
            <p>Informações foram salvas com sucesso!</p>
            <span>
              Aguarde alguns segundos, estamos finalizando a tua proposta!
            </span>
            <div className="counterContainer">
              <p style={{ position: "absolute", top: "40%" }}>
                00:
                {webSocketTimeout < 10
                  ? `0${webSocketTimeout}`
                  : webSocketTimeout}
              </p>
              <S.Loader2>
                <div className="lds-default">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </S.Loader2>
            </div>
          </div>
        </S.CustomPopup>
      </Grid>
    </Layout.View>
  );
};

export default CadComplete;
