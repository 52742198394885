import CryptoJS from "crypto-js";
import Axios from "axios";
import Repository from "repository";
import constants from "utils/constants";

// Credenciais do FaceCaptcha
const USER_1 = process.env.REACT_APP_LIVENESS_USER_1;
const PASSWORD_1 = process.env.REACT_APP_LIVENESS_PASSWORD_1;
const USER_2 = process.env.REACT_APP_LIVENESS_USER_2;
const PASSWORD_2 = process.env.REACT_APP_LIVENESS_PASSWORD_2;

const getFaceCaptchaCredentials = () => {
  const origem = Repository.storage.get(Repository.keys.ID_ORIGEM);
  let payload;

  if (origem && origem.tipo !== "LOJA") {
    payload = {
      user: USER_2,
      pass: PASSWORD_2,
    };
  } else {
    payload = {
      user: USER_1,
      pass: PASSWORD_1,
    };
  }

  return payload;
};

/**
 * ************************************************************
 * Instanciar essa função para usar o processo de prova de vida
 * @returns 
 */
const FaceCaptchaService = () => ({
  start,
  captcha,
});

/**
 * ************************************************************
 * ////// STEP 1 //////
 * 
 * Inicia o processo pegando as credenciais e os detalhes do desafio 
 * 
 * @param {*} name - Nome do usuario que ira passar pelo liveness
 * @param {*} birthday - Data de aniversario do usuario que ira passar pelo liveness
 * @param {*} cpf - CPF do usuario que ira passar pelo liveness
 * 
 * @returns - Retorna o desafio com o time e informações que devem ser usados no front para executar o desafio
 */
const start = async (name, birthday, cpf) => {
  const response = HttpResponse();

  const user = User();
  user.name = name;
  user.birthday = birthday;
  user.cpf = cpf;

  try {
    const credentialRes = await getCredential();
    if (credentialRes.status !== 200) return returnError(credentialRes);
    const credential = Object.assign(CredentialResponse, credentialRes.data);

    const appKeyResponse = await getAppkey(JSON.stringify(credential), user);
    if (appKeyResponse.status !== 200) return returnError(appKeyResponse);
    const appKey = Object.assign(AppKeyResponse, appKeyResponse.data);

    const challengeResponse = await getChallenge(appKey.appkey);
    if (challengeResponse.status !== 200) return returnError(challengeResponse);
    const challenge = Object.assign(ChallengeResponse, challengeResponse.data);

    response.status = 200;
    response.message = "OK";
    response.data = {
      credential,
      appKey,
      challenge,
    };

    return response;
  } catch (e) {
    return returnError(e);
  }
};

////// STEP 2 //////
/* Envia a image + o responnse do step 1 

  @return - Retorna o resultado do desafio, que em caso de 
erro deve ser mostrado em uma modal
*/
const captcha = async (startResponse, images = []) => {
  const response = HttpResponse();

  try {
    const appkey = startResponse.data?.appKey?.appkey;
    const chkey = startResponse.data?.challenge?.chkey;

    const captchaRes = await postCaptcha(appkey, chkey, images);
    if (captchaRes.status !== 200) return returnError(captchaRes);
    const captcha = Object.assign(CaptchaResponse, captchaRes.data);

    const resultRes = await postResult(appkey);
    if (resultRes.status !== 200) return returnError(resultRes);
    const result = Object.assign(ResultResponse, resultRes.data);

    response.status = 200;
    response.message = "OK";
    response.success = true;
    response.data = {
      startResponse,
      images,
      captcha,
      result,
    };

    return response;
  } catch (e) {
    return returnError(e);
  }
};

export const getCertifaceResultMessage = (code) => {
  switch (code) {
    case 1.1:
      return "Prova de vida feita.";
    case 1.2:
      return "Prova de vida feita.";
    case 100.1:
      return "Face não encontrada.";
    case 100.2:
      return "Posicionamento não frontal.";
    case 100.3:
      return "Você está muito próximo a câmera.";
    case 100.4:
      return "Você esta muito longe da câmera.";
    case 100.5:
      return "Existe mais de uma face nas imagem.";
    case 100.6:
      return "Iluminação inadequada.";
    case 200.1:
      return "Face enviada não é a face cadastrada, ou tem similar com cpf diferente.";
    case 300.1:
      return "Você não executou os desafios de forma adequada.";
    case 300.2:
      return "Você não executou os desafios de forma adequada.";
    default:
      return "Não foi detectado movimentos corretos. Vamos repetir o processo.";
  }
};

export const getImageEnc = (image, code) => {
  const base64Splited = image.split(",");
  const newImage =
    "data:image/jpeg;base64,type:" + code + "," + base64Splited[1];
  return newImage;
};

const returnError = (e) => {
  const response = HttpResponse();

  response.status = 500;
  response.message = "Erro ao executar FaceCaptcha";
  response.data = e;

  return response;
};

/************************************************************ */
/************************************************************ */
/************************************************************ */
/**************  CHAMADAS DA API DO FACECAPTCHA **************/
/************************************************************ */

const getCredential = async () => {
  const httpResponse = HttpResponse();

  const payload = getFaceCaptchaCredentials();

  const res = await Axios.post(constants.endpoints.livenessCredential, payload);

  return Object.assign(httpResponse, res);
};

const getAppkey = async (token, user) => {
  const httpResponse = HttpResponse();

  const credentials = getFaceCaptchaCredentials();

  const payload = {
    user: credentials.user,
    token: token,
    cpf: user.cpf,
    nascimento: user.birthday,
    nome: user.name,
  };

  const res = await Axios.post(constants.endpoints.livenessAppKey, payload);

  return Object.assign(httpResponse, res);
};

const getChallenge = async (appKey) => {
  const httpResponse = HttpResponse();

  const payload = {
    appkey: appKey,
  };

  const res = await Axios.post(constants.endpoints.livenessChallenge, payload);

  return Object.assign(httpResponse, res);
};

const postCaptcha = async (appKey, chKey, data) => {
  const httpResponse = HttpResponse();

  //usar esses aqui quando tratar o backend Opah
  const URL = constants.endpoints.livenessCaptcha;
  const payload = {
    appkey: appKey,
    chkey: chKey,
    images: encChData(data, appKey),
  };

  /////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////
  // usar o URL e Payload acima resolver o problema no backend Opah comy o Romulo
  ///////////////
  /// Prod: https://www.certiface.com.br:8443/facecaptcha/service/captcha
  /// Homolog: https://comercial.certiface.com.br/facecaptcha/service/captcha
  const URL_OITI =
    "https://comercial.certiface.com.br/facecaptcha/service/captcha";
  const payload_OITI_FormUrlEncoded = new URLSearchParams(payload);
  /////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////
  ///////////////
  /// Prod: payload_OITI_FormUrlEncoded
  /// Homolog: payload

  // const res = await Axios.post(URL_OITI, payload_OITI_FormUrlEncoded);
  const res = await Axios.post(URL, payload);

  return Object.assign(httpResponse, res);
};

const postResult = async (appKey) => {
  const httpResponse = HttpResponse();

  const payload = {
    appkey: appKey,
  };

  const URL = constants.endpoints.livenessResult;

  const URL_OITI =
    "https://comercial.certiface.com.br/facecaptcha/service/captcha/result";
  const payload_OITI_FormUrlEncoded = new URLSearchParams(payload);

  const res = await Axios.post(URL, payload);

  return Object.assign(httpResponse, res);
};

/******************************************************** */
/******************************************************** */
/******************************************************** */
// MODELS usadas no processo do faceCaptcha
/******************************************************** */

const User = () => ({
  name: "",
  birthday: "",
  cpf: "",
});

const HttpResponse = () => ({
  status: 500,
  message: "",
  data: null,
});

const CredentialResponse = {
  expires: "",
  token: "",
};

const AppKeyResponse = {
  appkey: "",
};

const ChallengeResponse = {
  chkey: "",
  numberOfChallenges: 0,
  snapFrequenceInMillis: 0,
  snapNumber: 0,
  totalTime: 0,
};

const ChallengeItemResponse = {
  mensagem: "",
  tempoEmSegundos: 0,
  tipoFace: {
    codigo: "",
    imagem: "",
  },
};

const CaptchaResponse = {
  cause: "",
  codID: 0,
  protocol: "",
  valid: true,
};

const ResultResponse = {
  appkey: "",
};

/**************************************************** */
/**************************************************** */
/**************************************************** */

const padKey = (source) => {
  if (source.length > 16) {
    return source.substring(0, 16);
  }
  return padMsg(source);
};

function padMsg(source) {
  var paddingChar = " ";
  var size = 16;
  var x = source.length % size;
  var padLength = size - x;
  for (var i = 0; i < padLength; i++) source += paddingChar;
  return source;
}

export const decChData = (data, appkey) => {
  var key = CryptoJS.enc.Latin1.parse(padKey(appkey));
  var iv = CryptoJS.enc.Latin1.parse(
    padKey(appkey.split("").reverse().join(""))
  );
  var decripted2 = CryptoJS.enc.Utf8.stringify(
    CryptoJS.AES.decrypt(data, key, {
      iv: iv,
      padding: CryptoJS.pad.NoPadding,
      mode: CryptoJS.mode.CBC,
    })
  );
  decripted2 = decripted2.substring(0, decripted2.lastIndexOf("}") + 1);
  decripted2 = decripted2.trim();
  return decripted2;
};

const encChData = (data, appkey) => {
  var key = CryptoJS.enc.Latin1.parse(padKey(appkey));
  var iv = CryptoJS.enc.Latin1.parse(
    padKey(appkey.split("").reverse().join(""))
  );
  var result = CryptoJS.AES.encrypt(padMsg(data), key, {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
  }).toString();
  return encodeURIComponent(result);
};

/**************************************************** */
/**************************************************** */
/**************************************************** */

export default FaceCaptchaService;
