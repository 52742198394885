import styled from "styled-components";

export const DivGroupBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 8px;
  flex-direction: initial;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

export const BtnContainer = styled.div`
  display: flex;
  gap: 2px;
`;
export const BtnMinus = styled.button`
  border: none;
  background-color: transparent;
  font-size: 13px;
  height: 2.5em;
  width: 2.5em;
  position: relative;

  &:after,
  &:before {
    content: "";
    display: block;
    background-color: red;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:before {
    width: 0.2em;
  }

  &:after {
    height: 0.2em;
    width: 1em;
  }
`;
export const BtnPlus = styled.button`
  border: none;
  background-color: transparent;
  font-size: 13px;
  height: 2.5em;
  width: 2.5em;
  position: relative;

  &:after,
  &:before {
    content: "";
    display: block;
    background-color: green;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:before {
    height: 1em;
    width: 0.2em;
  }

  &:after {
    height: 0.2em;
    width: 1em;
  }
`;

export const TableTitle = styled.h4`
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
`;

export const Table = styled.table`
  color: ${({ theme }) => theme.colors.primary};
  border-spacing: 0;
  text-align: center;

  th {
    background-color: #f5f5f5;
    padding: 10px 0;
  }

  th,
  td {
    border-bottom: 1px solid gray;
  }

  th {
    border-top: 1px solid gray;
  }

  td a {
    text-decoration: underline;
    color: inherit;
  }

  .icon-table {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const TableDependents = styled.table`
  color: ${({ theme }) => theme.colors.primary};
  border-spacing: 0;
  margin-top: 20px;
  text-align: center;
  border-left: 1px solid gray;
  border-right: 1px solid gray;

  td {
    padding: 10px 0;
  }
  th {
    background-color: #f5f5f5;
    padding: 10px 0;
  }

  th,
  td {
    border-bottom: 1px solid gray;
  }

  th {
    border-top: 1px solid gray;
  }

  td a {
    text-decoration: underline;
    color: inherit;
  }

  .icon-table {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
