/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useLayoutEffect, useState } from "react";
import { BrowserRouter, Redirect, Switch } from "react-router-dom";
import AccessPoints from "views/acessPoints";
import CadComplete from "views/cadComplete";
import CadMinimum from "views/cadMinimum";
import CardDesign from "views/cardDesign";
import FollowUp from "views/followUp";
import ForgotPassEmail from "views/forgotPassEmail";
import ForgotPassNew from "views/forgotPassNew";
import Login from "views/login";
import Proposal from "views/proposal";
import ContinueProposal from "views/continueProposal";
import ProposalCompleted from "views/proposalCompleted";
import ProposalQuery from "views/proposalQuery";
import ConsultProposalStatus from "views/consultProposalStatus";
import ConsultProposalBeforeSignature from "views/consultProposalBeforeSignature";
import Attendance from "views/attendance";
import AttendanceDetail from "views/attendanceDetail";
import BenefitDetail from "views/benefitDetail";
import SendByEmail from "views/sendByEmail";
import SignatureProposed from "views/signature";
import PushSMS from "views/pushSMS";
import LoadingProposta from "views/cadMinimum/loadingProposta";
import LoadingPush from "views/pushSMS/LoadingPush";
import SignatureCancelBenefit from "views/signatureCancelBenefit";
import SignatureNewBenefit from "views/signatureNewBenefit";
import SendEmailBenefit from "views/signatureNewBenefit/sendEmail";
import SignatureNewBenefitCompleted from "views/signatureNewBenefit/signatureCompleted";
import MyPerformance from "views/performance";
import ConsultClients from "views/leadsCenter/consultClient";
import DetailClientLeads from "views/leadsCenter/detailClientLeads";
import AddClientLeads from "views/leadsCenter/addClientLeads";

import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import { AuthContext } from "context/AuthContext";
import PaymentSimulator from "views/paymentSimulate/paymentSimulate";
import ChooseSimulator from "views/chooseSimulator/chooseSimulator";
import CreditCardLoanSimulator from "views/creditCardLoanSimulator/creditCardLoanSimulator";
import CreditCardLoanSimulatorDataConfirmation from "views/creditCardLoanSimulator/dataConfirmation";
import CreditCardLoanSimulatorActiveLeads from "views/creditCardLoanSimulator/activeLeads";
import CreditCardLoanSimulatorChooseType from "views/creditCardLoanSimulator/chooseType";
import CreditCardLoanSimulatorValueCalculation from "views/creditCardLoanSimulator/valueCalculation";
import CreditCardLoanSimulatorValueResult from "views/creditCardLoanSimulator/valueResult";
import CreditCardLoanSimulatorAditionalData from "views/creditCardLoanSimulator/aditionalData";
import CreditCardLoanSimulatorBankData from "views/creditCardLoanSimulator/bankData";
import CreditCardLoanSimulatorFinish from "views/creditCardLoanSimulator/finish";
import MonthlyHistory from "views/performance/monthlyHistory/monthlyHistory";
import WithDrawByTeuCard from "views/withDrawByTeuCardSimulator/welcomeByTeuCardWithDraw/withDrawByTeuCard";
import ConfirmDataByTeuCard from "views/withDrawByTeuCardSimulator/confirmDataByTeuCard/confirmDataByTeuCard";
import WithDrawSimulationByTeuCard from "views/withDrawByTeuCardSimulator/withDrawSimulationByTeuCard/withDrawSimulationByTeuCard";
import SimulationResultByTeuCard from "views/withDrawByTeuCardSimulator/simulationResultByTeuCard/simulationResultByTeuCard";
import WithDrawAdditionalData from "views/withDrawByTeuCardSimulator/withDrawAdditionalData/withDrawAddionalData";
import WithDrawBankData from "views/withDrawByTeuCardSimulator/withDrawBankingData/withDrawBankingData";
import WithdrawActiveLeads from "views/withDrawByTeuCardSimulator/WithdrawActiveLeads";
import WithDrawListenerStatus from "views/withDrawByTeuCardSimulator/withDrawListenerStatus/withDrawListenerStatus";
import WithDrawTermsSign from "views/withDrawByTeuCardSimulator/withDrawTermsSign/withDrawTermsSign";
import Liveness from "views/withDrawByTeuCardSimulator/Liveness/liveness";
import WithDrawConfirmSolicitation from "views/withDrawByTeuCardSimulator/withDrawConfirmSolicitation/withDrawConfirmSolicitation";
import ConfirmDataLoanSeeker from "views/loanSeeker/confirmDataLoanSeeker/confirmDataLoanSeeker";
import LoanSeekerWelcome from "views/loanSeeker/loanSeekerWelcome/loanSeekerWelcome";
import AdditionalDataLoanSeeker from "views/loanSeeker/AdditionalDataLoanSeeker";
import ComplementaryData from "views/loanSeeker/complementaryData";
import IdentificationData from "../views/loanSeeker/identificationData";
import ResidentialDataLoanSeeker from "views/loanSeeker/ResidentialDataLoanSeeker";
import BankingDataLoanSeeker from "views/loanSeeker/BankingDataLoanSeeker";
import SimulationByLoanSeeker from "views/loanSeeker/simulationByLoanSeeker";
import LeadsLoanSeeker from "views/loanSeeker/leadsLoanSeeker";
import SearchProposalLoanSeeker from "views/loanSeeker/searchProposalLoanSeeker";
import ProposalResultLoanSeeker from "views/loanSeeker/proposalResultsLoanSeeker";
import RequestDocumentsLoanSeeker from "views/loanSeeker/requestDocumentsLoanSeeker";
import AllProposalsLoanSeeker from "views/loanSeeker/allProposalsLoanSeeker";
import ChangeCategory from "views/changeCategory/changeCategory";
import changeCategorySignature from "views/changeCategorySignature";
import UpgradeSignature from "views/upgradeSignature/upgradeSignature";
import UpgradeCategoryConfirmation from "views/upgradeCategoryConfirmation/upgradeCategoryConfirmation";
import WithDrawKYCValidation from "views/withDrawByTeuCardSimulator/withDrawKYCValidation/withDrawKYCValidation";
import ProposalResume from "views/proposalResume";
import CardTemplate from "views/reprintCard/cardTemplate";
import LivennessReprintCard from "views/reprintCard/livennessReprintCard";


const Router = () => {
  const { setIDOrigem } = useContext(AuthContext);
  const [origem, setOrigem] = useState(true);

  useLayoutEffect(() => {
    function getOrigem() {
      if (window.location.search) {
        const searchParams = new URLSearchParams(window.location.search);

        const origem = searchParams.get("origem");

        setIDOrigem(origem);
        setOrigem(true);
      } else {
        setOrigem(false);
      }
    }
    getOrigem();
  }, []);
  return (
    <BrowserRouter>
      <Switch>
        <PrivateRoute exact path="/opcoes-acesso" component={AccessPoints} />

        <PrivateRoute exact path="/escolher-cartao" component={CardDesign} />

        {!origem && (
          <PrivateRoute exact path="/cadastro-minimo" component={CadMinimum} />
        )}

        {origem && (
          <PublicRoute exact path="/cadastro-minimo" component={CadMinimum} />
        )}

        {!origem && (
          <PrivateRoute
            exact
            path="/proposta-resumo"
            component={ProposalResume}
          />
        )}
        {origem && (
          <PublicRoute
            exact
            path="/proposta-resumo"
            component={ProposalResume}
          />
        )}

        {!origem && (
          <PrivateRoute
            exact
            path="/loading-proposta"
            component={LoadingProposta}
          />
        )}

        {origem && (
          <PublicRoute
            exact
            path="/loading-proposta"
            component={LoadingProposta}
          />
        )}

        {!origem && (
          <PrivateRoute
            exact
            path="/cadastro-completo"
            component={CadComplete}
          />
        )}

        <PublicRoute exact path="/push-SMS" component={PushSMS} />

        <PublicRoute exact path="/loading-push" component={LoadingPush} />

        {origem && (
          <PublicRoute
            exact
            path="/cadastro-completo"
            component={CadComplete}
          />
        )}

        <PrivateRoute
          exact
          path="/acompanhamento-proposta"
          component={FollowUp}
        />
        <PrivateRoute
          exact
          path="/consulta-status-proposta"
          component={ConsultProposalStatus}
        />
        <PrivateRoute exact path="/consulta-proposta-antes-assinatura" component={ConsultProposalBeforeSignature} />
        <PrivateRoute exact path="/attendance" component={Attendance} />
        <PrivateRoute
          exact
          path="/attendance-detail"
          component={AttendanceDetail}
        />
        <PrivateRoute
          exact
          path="/change-category"
          component={ChangeCategory}
        />
        <PrivateRoute
          exact
          path="/change-category-signature"
          component={changeCategorySignature}
        />
        <PrivateRoute
          exact
          path="/upgrade-signature"
          component={UpgradeSignature}
        />
        <PrivateRoute
          exact
          path="/upgrade-category-confirmation"
          component={UpgradeCategoryConfirmation}
        />
        <PrivateRoute exact path="/beneficios" component={BenefitDetail} />
        <PrivateRoute exact path="/status-proposta" component={ProposalQuery} />
        <PrivateRoute exact path="/envio-email" component={SendByEmail} />

        <PrivateRoute
          exact
          path="/proposta-completa"
          component={ProposalCompleted}
        />
        <PrivateRoute
          exact
          path="/assinatura-proposta"
          component={SignatureProposed}
        />
        <PrivateRoute
          exact
          path="/assinatura-termo-cancelamento"
          component={SignatureCancelBenefit}
        />
        <PrivateRoute
          exact
          path="/assinatura-beneficio"
          component={SignatureNewBenefit}
        />
        <PrivateRoute
          exact
          path="/assinatura-beneficio-completa"
          component={SignatureNewBenefitCompleted}
        />
        <PrivateRoute
          exact
          path="/assinatura-beneficio-email"
          component={SendEmailBenefit}
        />
        <PrivateRoute exact path="/proposta" component={Proposal} />
        <PrivateRoute
          exact
          path="/continuar-assinatura-proposta"
          component={ContinueProposal}
        />
        <PrivateRoute exact path="/desempenho" component={MyPerformance} />
        <PrivateRoute
          exact
          path="/historico-mensal"
          component={MonthlyHistory}
        />
        <PrivateRoute exact path="/consult-leads" component={ConsultClients} />
        <PrivateRoute
          exact
          path="/detail-leads"
          component={DetailClientLeads}
        />
        <PrivateRoute exact path="/add-leads" component={AddClientLeads} />
        <PrivateRoute
          exact
          path="/payment-simulator"
          component={PaymentSimulator}
        />
        <PrivateRoute
          exact
          path="/credit-card-loan-simulator"
          component={CreditCardLoanSimulator}
        />
        <PrivateRoute
          exact
          path="/with-draw-by-teu-card"
          component={WithDrawByTeuCard}
        />
        <PrivateRoute
          exact
          path="/confirm-data-by-teu-card"
          component={ConfirmDataByTeuCard}
        />
        <PrivateRoute
          exact
          path="/with-draw-confirm-solicitation"
          component={WithDrawConfirmSolicitation}
        />
        <PrivateRoute exact path="/loan-seeker" component={LoanSeekerWelcome} />
        <PrivateRoute
          exact
          path="/confirm-data-loan-seeker"
          component={ConfirmDataLoanSeeker}
        />
        <PrivateRoute
          exact
          path="/additional-data-loan-seeker"
          component={AdditionalDataLoanSeeker}
        />
        <PrivateRoute
          exact
          path="/complementary-data-loan-seeker"
          component={ComplementaryData}
        />
        <PrivateRoute
          exact
          path="/identification-data-loan-seeker"
          component={IdentificationData}
        />
        <PrivateRoute
          exact
          path="/residential-data-loan-seeker"
          component={ResidentialDataLoanSeeker}
        />
        <PrivateRoute
          exact
          path="/banking-data-loan-seeker"
          component={BankingDataLoanSeeker}
        />
        <PrivateRoute
          exact
          path="/simulation-loan-seeker"
          component={SimulationByLoanSeeker}
        />
        <PrivateRoute
          exact
          path="/leads-loan-seeker"
          component={LeadsLoanSeeker}
        />
        <PrivateRoute
          exact
          path="/search-proposal-loan-seeker"
          component={SearchProposalLoanSeeker}
        />
        <PrivateRoute
          exact
          path="/proposal-result-loan-seeker"
          component={ProposalResultLoanSeeker}
        />
        <PrivateRoute
          exact
          path="/request-documents-loan-seeker"
          component={RequestDocumentsLoanSeeker}
        />
        <PrivateRoute
          exact
          path="/all-proposals-loan-seeker"
          component={AllProposalsLoanSeeker}
        />
        <PrivateRoute
          exact
          path="/with-draw-simulation-by-teu-card"
          component={WithDrawSimulationByTeuCard}
        />
        <PrivateRoute
          exact
          path="/simulation-result-by-teu-card"
          component={SimulationResultByTeuCard}
        />
        <PrivateRoute
          exact
          path="/with-draw-additional-data"
          component={WithDrawAdditionalData}
        />
        <PrivateRoute
          exact
          path="/with-draw-bank-data"
          component={WithDrawBankData}
        />
        <PrivateRoute
          exact
          path="/with-draw-active-leads"
          component={WithdrawActiveLeads}
        />
        <PrivateRoute
          exact
          path="/with-draw-listener-status"
          component={WithDrawListenerStatus}
        />
        <PrivateRoute
          exact
          path="/with-draw-kyc-validation"
          component={WithDrawKYCValidation}
        />
        <PrivateRoute
          exact
          path="/with-draw-terms-sign"
          component={WithDrawTermsSign}
        />
        <PrivateRoute exact path="/liveness" component={Liveness} />
        <PrivateRoute
          exact
          path="/credit-card-loan-simulator-data-confirmation"
          component={CreditCardLoanSimulatorDataConfirmation}
        />
        <PrivateRoute
          exact
          path="/credit-card-loan-simulator-active-leads"
          component={CreditCardLoanSimulatorActiveLeads}
        />

        <PrivateRoute
          exact
          path="/credit-card-loan-simulator-choose-type"
          component={CreditCardLoanSimulatorChooseType}
        />
        <PrivateRoute
          exact
          path="/credit-card-loan-simulator-value"
          component={CreditCardLoanSimulatorValueCalculation}
        />
        <PrivateRoute
          exact
          path="/credit-card-loan-simulator-value-result"
          component={CreditCardLoanSimulatorValueResult}
        />
        <PrivateRoute
          exact
          path="/credit-card-loan-simulator-aditional-data"
          component={CreditCardLoanSimulatorAditionalData}
        />
        <PrivateRoute
          exact
          path="/credit-card-loan-simulator-bank-data"
          component={CreditCardLoanSimulatorBankData}
        />
        <PrivateRoute
          exact
          path="/credit-card-loan-simulator-finish"
          component={CreditCardLoanSimulatorFinish}
        />
        <PrivateRoute
          exact
          path="/choose-simulator"
          component={ChooseSimulator}
        />
        <PrivateRoute exact path="/cardTemplateReprint" component={CardTemplate}/>
        <PrivateRoute exact path="/livenessReprintCard" component={LivennessReprintCard} />
        <PublicRoute
          exact
          path="/email-forgot-password"
          component={ForgotPassEmail}
        />
        <PublicRoute exact path="/forgotNewPass" component={ForgotPassNew} />
        <PublicRoute exact path="/" component={Login} />
        <Redirect to="/opcoes-acesso" />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
