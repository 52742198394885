import styled from "styled-components";
import media from "styled-media-query";

export const SafeView = styled.div`
  display: flex;
  margin: 30px;
  padding-bottom: 50px;
  background-color: #fff;
`;

export const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
`;

export const TopSpace = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  gap: 40px;

  ${media.greaterThan("medium")`
  width: 100%;
  height: 35%;
  `}
`;

export const Title = styled.p`
  font-size: 20px;
  font-weight: 400;
  text-align: start;
  margin-bottom: 20px;
`;

export const ContainerIconRecognitionIcon = styled.div`
    width: 50%;
    margin: auto;
    display: flex;
    justify-content: center;
`;

export const ContainerValidFace = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 600px;
  margin: auto;
  text-align: center;
  gap: 100px;

  h1 {
    color: #E4003A;
    font-size: 25px;
  }

  h2 {
    font-size: 20px;
    font-weight: 400;
  }
`;

export const ContainerTips = styled.div`
  margin: auto;
  width: 50%;
  height: auto;
  border-radius: 8px;
  background-color: #F7F7F7;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const TipLine = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  span {
    font-size: 2rem;
  }

  img {
    width: 18px;
    height: 18px;
  }
`;

export const Footer = styled.div`
  margin-top: 55px;
  bottom: 0;
  width: 100%;

  @media (max-width: 712px) {
  padding: 20px;
  text-align: center;
  left: 0;
  bottom: 0;
  width: 100%;
  }
`;

export const Picture = styled.div`
  grid-area: camera;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px 0;

  @media screen and (max-width: 500px) {
    justify-content: center;
  }

  #picture-modal {
    margin-right: 20px;
  }

  /* button {
    display: flex;
    align-items: center;
    width: 100% !important;
    height: 40px !important;
    margin: 0 20px 0 0 !important;
    svg {
      margin-right: 10px;
    }
  } */
`;

export const WrapperCamera = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 10000;
  width: 100vw;
  height: 100vh;

  visibility: ${({ show }) => (show ? "visible" : "hidden")};
`;

export const ErrorMessage = styled.span`
  color: ${({
    theme: {
      colors: { red },
    },
  }) => red};
  font-size: 1.5rem;
`;