/* eslint-disable react-hooks/exhaustive-deps */
import Button from "components/Button";
import TwoButtonPopUp from "components/modals/TwoButtonPopup";
import Layout from "components/OldLayout";
import ProposalCard from "components/ProposalCard";
import Spinner from "components/Spinner";
import moment from "moment/min/moment-with-locales";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import themes from "styles";
import useReactRouter from "use-react-router";
import constants from "utils/constants";
import { cpfMask } from "utils/mask";
import PictureModal from "components/PictureCard/PictureCardPerc";
import assets from "assets/index";
import * as S from "./styles";
import useApi from "hooks/useApi";
import BenefitSignature from "components/BenefitSignature";
import useWebSocket from "react-use-websocket";
moment.locale("pt-br");

const CadProposta = () => {
  const [isLoading, setLoading] = useState(false);
  const [exclusionModalOpen, setExclusionModalOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState({});
  const [contratoInfo, setContratoInfo] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [errorContractsModal, setErrorContractsModal] = useState(false);
  const [isLoadingMessage, setIsLoadingMessage] = useState(false);
  const [allSignatures, setAllSignatures] = useState([]);
  const { history } = useReactRouter();
  const { state } = useLocation();
  const [benefitsContracteds, setBenefitsContracteds] = useState([]);
  const [socketConnectionOpen, setSocketConnectionOpen] = useState(false);
  const [socketResponseMessage, setSocketResponseMessage] = useState("");
  const [waitingSocketResponseModal, setWaitingSocketResponseModal] =
    useState(false);
  const [webSocketTimeout, setWebsocketTimeout] = useState(59);
  const [timeoutModal, setTimeoutModal] = useState(false);

  useEffect(() => {
    if (state?.benefits) {
      setBenefitsContracteds(
        state?.benefits?.contracted?.map((e) => ({
          ...e,
          tipoContrato: "BENEFICIO",
        }))
      );
      setAllSignatures([
        ...state.benefits.contracted.map((e) => ({
          ...e,
          tipoContrato: "BENEFICIO",
        })),
        {
          cpf: state.cpf,
          idProposta: state.propostaId,
          linkContrato: "",
          nome: "Proposta padrão",
          valorMensalidade: 0,
          tipoContrato: "TERMO_TEUCARD",
        },
      ]);
    }
  }, [state?.benefits]);

  useEffect(() => {
    if (benefitsContracteds && state?.themeSelected === "PLATINUM") {
      setBenefitsContracteds((prev) => [
        ...prev,
        { ...state.cardInfo, tipoContrato: "TERMO_TEUCARD_ADICIONAL" },
      ]);
      setAllSignatures((prev) => [
        ...prev,
        { ...state.cardInfo, tipoContrato: "TERMO_TEUCARD_ADICIONAL" },
      ]);
    }
  }, []);

  const activeButton = useMemo(() => {
    return (
      benefitsContracteds.length + 1 ===
      allSignatures.filter((e) => e.statusAssinaturaContrato === "ASSINADO")
        .length
    );
  }, [allSignatures, benefitsContracteds]);

  const GetSpcProposalData = useApi(
    constants.endpoints.spcProposalData.replace(
      "PARAM_ID_PROPOSAL",
      state?.propostaId
    ),
    "GET"
  );
  const GetClientDocuments = useApi(
    constants.endpoints.getClientDocument.replace(
      "PARAM_QUERY_STRING",
      state?.propostaId
    ),
    "GET"
  );

  const GetProposalClientModal = useApi(
    constants.endpoints.proposalClientModal.replace(
      "PARAM_ID_PROPOSAL",
      state?.propostaId
    ),
    "GET"
  );

  const PostPrepareContractsProposal = useApi(
    constants.endpoints.postPrepareCreateContracts,
    "POST"
  );

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { data } = await GetSpcProposalData.callApi();

        const res = await GetClientDocuments.callApi();

        const responseModal = await GetProposalClientModal.callApi();

        const modalInfo = {
          email: responseModal?.data?.email,
          nome: responseModal?.data?.nomeCompleto,
          diaVencimento: responseModal?.data?.diaVencimento,
          limiteTotal:
            responseModal?.data?.limiteCredito +
            responseModal?.data?.limiteSaqueConcedido,
          limiteCredito: responseModal?.data?.limiteCredito,
          limiteSaque: responseModal?.data?.limiteSaqueConcedido,
          fotoCertiface: res.data.fotoCertiface,
        };

        const contratoInfo = {
          nome: data.proposta.nome,
          cpf: cpfMask(data.proposta.cpf).masked,
          endereco: data.proposta.endereco,
        };

        setContratoInfo(contratoInfo);
        setModalInfo(modalInfo);

        // setModalIsOpen(true);

        setLoading(false);
      } catch (err) {
        console.error(err.message);
      } finally {
        setLoading(false);
      }
    })();
  }, [state.propostaId]);

  const DeleteSavedContracts = useApi(
    constants.endpoints.deleteSavedBenefits.replace(
      "PARAM_ID_PROPOSTA",
      state.propostaId
    ),
    "DELETE"
  );
  const handleExcludeSavedContracts = async () => {
    const response = await DeleteSavedContracts.callApi();
    if (response.status === 200) {
      history.push("/consulta-status-proposta");
    }
  };
  useEffect(() => {
    let timer;
    if (socketConnectionOpen) {
      if (webSocketTimeout === 0) {
        setTimeoutModal(true);
        return;
      }
      timer = setTimeout(() => {
        setWebsocketTimeout((prev) => prev - 1);
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [socketConnectionOpen, webSocketTimeout]);
  const createSocketConnectionUrl = useCallback(() => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(
          constants.endpoints.postWebsocketContractGeneration.replace(
            "PARAM_ID_PROPOSTA",
            state?.propostaId
          )
        );
      }, 1000);
    });
  }, []);

  const { readyState, lastJsonMessage } = useWebSocket(
    createSocketConnectionUrl,
    {},
    socketConnectionOpen
  );

  useEffect(() => {
    if (!!lastJsonMessage && readyState === 1) {
      const { contratosGerados } = lastJsonMessage;
      if (!contratosGerados) {
        setErrorContractsModal(true);
        const msg = JSON.parse(lastJsonMessage?.erro);
        setSocketResponseMessage(msg?.mensagem);
        setSocketConnectionOpen(false);
        return;
      } else {
        history.push("/proposta-completa", {
          ...state,
          cpf: contratoInfo.cpf,
          propostaId: state.propostaId,
          benefits: benefitsContracteds,
        });
      }
    }
  }, [lastJsonMessage, readyState]);
  const handleSubmit = async () => {
    setIsLoadingMessage(true);
    const response = await PostPrepareContractsProposal.callApi({
      idProposta: state?.propostaId,
      idOperador: JSON.parse(localStorage.getItem("@NovaEra:user")).id,
    });
    if (response.status === 200) {
      createSocketConnectionUrl();

      setSocketConnectionOpen(true);
      setWaitingSocketResponseModal(true);
    }
  };

  return (
    <S.Wrapper>
      <Layout
        routeBack="/consulta-status-proposta"
        title="Proposta do cartão"
        icon={
          <div
            onClick={() => setExclusionModalOpen(true)}
            style={{ position: "absolute", right: 0, cursor: "pointer" }}
          >
            <img
              width={40}
              height={40}
              src={themes.components.png.cancel}
              alt="cancelarAssinaturas"
            />
          </div>
        }
      >
        <TwoButtonPopUp
          visibility={modalIsOpen}
          buttonText="Aceitar"
          onClick={() => setModalIsOpen(false)}
          buttonCancelText="Recusar"
          onClickCancel={() => history.push("/opcoes-acesso")}
          width={55}
          setVisibility={(val) => setModalIsOpen(val)}
        >
          {modalInfo && (
            <>
              <S.ConfirmProfile>
                <S.divClient>
                  <S.Picture>
                    <PictureModal
                      id="picture-modal"
                      source={
                        modalInfo.fotoCertiface
                          ? `data:image/png;base64, ${modalInfo.fotoCertiface}`
                          : assets.png.userAvatar
                      }
                      width="190"
                      height="190"
                      expand
                    />
                  </S.Picture>
                  <div style={{ marginTop: 20 }}>
                    <p>Status: BLOQUEADO</p>
                    <p>Nome do cliente: {modalInfo.nome}</p>
                    <p>E-mail: {modalInfo.email}</p>
                    <p>
                      Dia do vencimento da fatura: {modalInfo.diaVencimento}
                    </p>
                    <p>Limite de crédito total: R$ {modalInfo.limiteTotal}</p>
                    <p>
                      Limite de crédito para compras: R${" "}
                      {modalInfo.limiteCredito}
                    </p>
                    <p>
                      Limite de crédito para saque: R$ {modalInfo.limiteSaque}
                    </p>
                  </div>
                </S.divClient>
              </S.ConfirmProfile>
            </>
          )}
        </TwoButtonPopUp>

        <ProposalCard
          date={moment(new Date()).format("DD [de] MMMM [de] YYYY")}
          infos={contratoInfo}
          benefit={state?.benefits.contracted?.[0]}
        />

        <S.SignatureGrid>
          <>
            {allSignatures
              ?.sort((a, b) => a.valorMensalidade - b.valorMensalidade)
              ?.map((benefit) => {
                return (
                  <BenefitSignature
                    setAllSignatures={setAllSignatures}
                    benefit={benefit}
                  />
                );
              })}
          </>
        </S.SignatureGrid>

        <div style={{ marginTop: "2rem" }}>
          <Button
            buttonType="primary"
            text={isLoadingMessage ? <Spinner color="#000" /> : "Continuar"}
            disabled={!activeButton || isLoading || isLoadingMessage}
            active={activeButton}
            onClick={() => handleSubmit()}
          />
        </div>

        <TwoButtonPopUp
          width={30}
          text={"Recomeçar o fluxo da assinatura"}
          visibility={exclusionModalOpen}
          setVisibility={setExclusionModalOpen}
          onClick={() => {
            handleExcludeSavedContracts();
          }}
          buttonText="Sim"
          buttonCancelText="Não"
          onClickCancel={() => setExclusionModalOpen(false)}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p>Todas as assinaturas serão apagadas, deseja continuar?</p>
          </div>
        </TwoButtonPopUp>

        <TwoButtonPopUp
          defaultButton
          text={socketResponseMessage}
          visibility={errorContractsModal}
          setVisibility={setErrorContractsModal}
          onClick={() => {
            history.push("/consulta-status-proposta");
          }}
          buttonText="Entendi"
        />
        <S.CustomPopup
          modal
          onClose={() => setWaitingSocketResponseModal(false)}
          open={waitingSocketResponseModal}
          position={"center center"}
          overlayStyle={{
            background: "rgba(0, 0, 0, 0.7)",
          }}
          closeOnDocumentClick={false}
          contentStyle={{ background: "white", width: "30%" }}
        >
          <S.CustomPopup
            modal
            nested
            onClose={() => setTimeoutModal(false)}
            open={timeoutModal}
            position={"center center"}
            overlayStyle={{
              background: "rgba(0, 0, 0, 0.7)",
              zIndex: 9999,
            }}
            closeOnDocumentClick={false}
            contentStyle={{
              background: "white",
              width: "50%",

              padding: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ display: "flex", alignItems: "left", gap: "30px" }}>
                {themes.components.icons.tick}
                <p
                  style={{
                    fontSize: "2rem",
                    fontWeight: "500",
                    marginBottom: 20,
                  }}
                >
                  Ops...Precisamos de um pouco mais de tempo!
                </p>
              </div>
              <span
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "500",
                  margin: "1rem 0",
                }}
              >
                Estamos gerando os contratos
              </span>
              <Button
                onClick={() => {
                  history.push("/proposta-completa", {
                    ...state,
                    cpf: contratoInfo.cpf,
                    propostaId: state.propostaId,
                    benefits: benefitsContracteds,
                  });
                }}
                style={{ marginTop: 10 }}
                buttonType="primary"
              >
                Continuar
              </Button>
            </div>
          </S.CustomPopup>
          <div className="container">
            <p>Informações foram salvas com sucesso!</p>
            <span>Aguarde alguns segundos, estamos gerando os contratos!</span>
            <div className="counterContainer">
              <p style={{ position: "absolute", top: "40%" }}>
                00:{webSocketTimeout}
              </p>
              <S.Loader2>
                <div className="lds-default">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </S.Loader2>
            </div>
          </div>
        </S.CustomPopup>
      </Layout>
    </S.Wrapper>
  );
};

export default CadProposta;
