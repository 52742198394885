import assets from "../assets";

const { png } = assets;
const components = {
  colors: {
    // buttonText: colors.white.n1,
    // button: colors.orange.n2,
    // font: colors.black.n1,
    // navItem: colors.gray.n3,
    // link: colors.orange.n2,
    // label: colors.gray.n1,
    // input: colors.black.n1,
    // messageError: colors.red.n1,
    // cardText: colors.white.n2,
  },
  png: {
    opah: png.opah,
    calendar: png.calendar,
    shape: png.shape,
    printer: png.printer,
    eye: png.eye,
    warning: png.warning,
    arrow: png.arrow,
    creditSimulator: png.cardSimulator,
    logo: png.logo,
    chip: png.chip,
    mastercard: png.logoMasterCard,
    visa: png.logoVisa,
    elo: png.logoElo,
    loan: png.loan,
    americanExpressFull: png.americanExpressFull,
    visaFull: png.visaFull,
    dinnersClubFull: png.dinnersClubFull,
    hiperCardFull: png.hiperCardFull,
    masterCardFull: png.masterCardFull,
    eloFull: png.eloFull,
    iconLimite: png.iconLimite,
    iconParcela: png.iconParcela,
    iconEmprestimo: png.iconEmprestimo,
    iconSaque: png.iconSaque,
    star: png.star,
    finish: png.finishIcon,
    finishError: png.finishError,
    ornament: png.ornament,
    withDraw: png.withDraw,
    teuCardCards: png.teuCardCards,
    visible: png.visible,
    invisible: png.invisible,
    emojiSad: png.emojiSad,
    emojiSuccess: png.emojiSuccess,
    searchLoans: png.searchLoans,
    teuCredBankings: png.teuCredBankings,
    information: png.information,
    cancel: png.cancel,
    faceRecognition: png.faceRecognition,
  },
  icons: { ...assets.icons },
};

export default components;
