/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Layout from "components/OldLayout";
import * as St from "./styles";
import Button from "components/Button";
import { useLocation, useHistory } from "react-router-dom";
import assets from "assets";
import useApi from "hooks/useApi";
import constants from "utils/constants";
import OneButtonPopup from "components/modals/OneButtonPopup";
import Loading from "components/Loading";

const CardTemplate = () => {
  const [popupVisibility, setPopupVisibility] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const history = useHistory();

  const idOperador = JSON.parse(localStorage.getItem("@NovaEra:user")).id;

  const { state } = useLocation();

  const GetReprintCard = useApi(
    constants.endpoints.getReprintCard
      .replace("PARAM_CPF", state?.cpf)
      .replace("PARAM_OPERADOR", idOperador),
    "GET"
  );

  const handleReprintCard = async () => {
    try {
      const response = await GetReprintCard.callApi();
      if (response.status === 200) {
        setPopupVisibility(true);
        setPopupMessage(response.mensagem ? response.mensagem : "Reimpressão autorizada.")
      } else {
        setPopupVisibility(true);
        setPopupMessage("Erro no serviço!")
      }
    } catch (error) {
      console.log(error);
    }
  };

  const cardRender = (card) => {
    switch (card) {
      case "PLATINUM":
        return <img src={assets.png.platinumCard} alt="Platinum" />;
      case "RED":
        return <img src={assets.png.redCard} alt="RED" />;
      case "ROSE":
        return <img src={assets.png.roseCard} alt="ROSE" />;
      case "BLUE":
        return <img src={assets.png.blueCard} alt="BLUE" />;
      case "LUDICO":
        return <img src={assets.png.whiteCard} alt="LUDICO" />;
      default:
        return null;
    }
  };

  return (
    <St.SafeView>
      <Loading status={GetReprintCard.loading}/>
      <Layout routeBack="/opcoes-acesso" title="Modelo do cartão">
        <OneButtonPopup
          defaultButton
          text={popupMessage}
          buttonText="OK"
          visibility={popupVisibility}
          setVisibility={() => {
            history.push("/opcoes-acesso");
            setPopupVisibility(false);
          }}
        />
        <St.Container>
          <St.TopSpace>
            <St.Title>
              Confira abaixo o modelo de cartão que precisa ser reimpresso.
            </St.Title>
            <St.ContainerCardTemplate>
              <St.Subtitle>Modelo: {state?.temaDoCartao}</St.Subtitle>
              <St.Card>{cardRender(state?.temaDoCartao)}</St.Card>
            </St.ContainerCardTemplate>
            <St.Footer>
              <Button
                buttonType="primary"
                text="Continuar"
                onClick={handleReprintCard}
              />
            </St.Footer>
          </St.TopSpace>
        </St.Container>
      </Layout>
    </St.SafeView>
  );
};

export default CardTemplate;
